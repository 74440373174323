import { cubejsApi } from '../../../app.functions';
import { Filter } from '@cubejs-client/core';

async function loadSprintData(individualUuid: string): Promise<any> {
    const filters: Filter[] = [
        {
            member: 'DimIndividual.uuid',
            operator: 'equals',
            values: [individualUuid],
        },
        {
            member: 'FactSprintsRanked.load',
            operator: 'equals',
            values: ['0', '1'],
        },
        {
            member: 'DimUpload.isDeleted',
            operator: 'equals',
            values: ['0'],
        },
    ];

    try {
        return await cubejsApi.load({
            measures: ['FactSprintsRanked.vMaxMaxMph', 'FactSprintsRanked.count'],
            filters: filters,
            timeDimensions: [
                {
                    dimension: 'DimDate.fullDate',
                    granularity: 'day',
                    dateRange: 'from 1 year ago to now',
                },
            ],
        });
    } catch (error) {
        console.error('Error loading data from Cube.js:', error);
        throw error;
    }
}

async function loadTimeSegmentKpis(individualUuid: string): Promise<any> {
    const filters: Filter[] = [
        {
            member: 'DimIndividual.uuid',
            operator: 'equals',
            values: [individualUuid],
        },
        {
            member: 'FactSprintTimeSegments.load',
            operator: 'equals',
            values: ['0', '1'],
        },
        {
            member: 'DimUpload.isDeleted',
            operator: 'equals',
            values: ['0'],
        },
        {
            member: 'DimTimeSegment.duration',
            operator: 'equals',
            values: ['1'],
        },
    ];

    try {
        return await cubejsApi.load({
            dimensions: [
                'FactSprintTimeSegments.factSprintTimeSegmentKey',
                'FactSprintTimeSegments.distanceFt',
                'FactSprintTimeSegments.averageVelocityMph',
                'DimUpload.uuid',
                'DimSprint.uuid',
            ],
            measures: ['FactSprintTimeSegments.velocityMaxMph'],
            filters: filters,
        });
    } catch (error) {
        console.error('Error loading data from Cube.js:', error);
        throw error;
    }
}

async function loadHighSpeedSprintsData(individualUuid: string): Promise<any> {
    const filters: Filter[] = [
        {
            member: 'DimIndividual.uuid',
            operator: 'equals',
            values: [individualUuid],
        },
        {
            member: 'FactSprintsRanked.load',
            operator: 'equals',
            values: ['0', '1'],
        },
        {
            member: 'DimUpload.isDeleted',
            operator: 'equals',
            values: ['0'],
        },
    ];

    try {
        return await cubejsApi.load({
            measures: ['FactSprintsRanked.vMaxMaxMph', 'FactSprintsRanked.count'],
            filters: filters,
            segments: ['FactSprintsRanked.isHighSpeedRun'],
            timeDimensions: [
                {
                    dimension: 'DimDate.fullDate',
                    granularity: 'day',
                    dateRange: 'from 1 year ago to now',
                },
            ],
        });
    } catch (error) {
        console.error('Error loading data from Cube.js:', error);
        throw error;
    }
}

async function loadLogCounts(individualUuid: string): Promise<any> {
    const filters: Filter[] = [
        {
            member: 'DimIndividual.uuid',
            operator: 'equals',
            values: [individualUuid],
        },
    ];

    try {
        return await cubejsApi.load({
            measures: ['FactActivities.count'],
            filters: filters,
            timeDimensions: [
                {
                    dimension: 'DimDateLocal.fullDate',
                    granularity: 'day',
                    // This Week
                    dateRange: 'from 1 year ago to now',
                },
            ],
        });
    } catch (error) {
        console.error('Error loading data from Cube.js:', error);
        throw error;
    }
}

export { loadSprintData, loadHighSpeedSprintsData, loadLogCounts, loadTimeSegmentKpis };
