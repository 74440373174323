import React from 'react';
import { AppBar, Tab, Tabs, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useHistory } from 'react-router-dom';
import useNavigationTabConfig, { NavigationTabConfigProps } from './navigationconfig';
import { useNavigationContext } from './NavigationContext';

const MobileBottomNavigation = () => {
    const { push } = useHistory();
    const { setPrimaryNavigation, primaryNavigation } = useNavigationContext();
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('sm'));
    const navigationTabConfig = useNavigationTabConfig();

    return (
        <AppBar
            position="fixed"
            component="footer"
            sx={{
                bottom: 0,
                top: 'auto',
                backgroundColor: theme.palette.common.white,
            }}
            color="transparent"
        >
            <Toolbar variant="dense" disableGutters>
                <Tabs
                    indicatorColor="primary"
                    value={primaryNavigation}
                    sx={{
                        width: '100%',
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Tab value={``} style={{ display: 'none' }} />
                    {navigationTabConfig.map(
                        (navigationTab: NavigationTabConfigProps, index: number) => (
                            <Tab
                                key={`navigation-tab-${index}`}
                                value={navigationTab.value}
                                sx={{
                                    padding: isNarrow ? theme.spacing(1) : theme.spacing(3),
                                    minWidth: isNarrow ? 'auto' : 'inherit',
                                    '& .MuiTypography-root': {
                                        fontWeight: 600,
                                    },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: isNarrow ? '1.2rem' : '1.2rem',
                                    },
                                }}
                                label={
                                    !isNarrow && (
                                        <Typography variant="caption">
                                            {navigationTab.label}
                                        </Typography>
                                    )
                                }
                                icon={<navigationTab.icon />}
                                onClick={() => {
                                    push(navigationTab.path);
                                    setPrimaryNavigation(navigationTab.value);
                                }}
                            />
                        ),
                    )}
                </Tabs>
            </Toolbar>
        </AppBar>
    );
};

export default MobileBottomNavigation;
