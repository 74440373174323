import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    Stack,
    Box,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { PhysicalProductForPurchase } from 'utils';

interface PhysicalItemPurchaseDialogProps {
    open: boolean;
    onClose: () => void;
    items: PhysicalProductForPurchase[];
    onPurchase: (selectedItems: Record<string, number>) => void;
    title: string;
    imageSource: string;
    shippingFee: number;
    subscription?: boolean;
}

const PhysicalItemPurchaseDialog: React.FC<PhysicalItemPurchaseDialogProps> = ({
    open,
    onClose,
    items,
    onPurchase,
    title,
    imageSource,
    shippingFee,
    subscription = false,
}) => {
    const [selectedItemCounts, setSelectedItemCounts] = useState<Record<string, number>>({});

    const handleItemCountChange = (priceId: string, count: number) => {
        setSelectedItemCounts((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const calculateTotalCost = () => {
        let totalCost = 0;
        items.forEach((item) => {
            const count = selectedItemCounts[item.stripe_price_id] || 0;
            const costForThisItem = (count * item.price) / 100;
            totalCost += costForThisItem;
        });
        return totalCost.toFixed(2);
    };

    const isPurchaseButtonDisabled = () => {
        return !Object.values(selectedItemCounts).some((count) => count > 0);
    };

    const handlePurchase = () => {
        onPurchase(selectedItemCounts);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {items.map((item) => (
                    <FormControl key={item.uuid} fullWidth margin="normal">
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box
                                component="img"
                                sx={{
                                    height: 24,
                                    width: 24,
                                    marginRight: 2,
                                }}
                                alt={item.name}
                                src={imageSource}
                            />
                            <Typography sx={{ width: 'calc(80% - 24px)', fontSize: '0.8rem' }}>
                                {item.name}
                                {subscription ? (
                                    <span style={{ fontWeight: '500' }}>
                                        {' $'}
                                        {(item.price / 100).toFixed(2)}/month
                                    </span>
                                ) : (
                                    <span style={{ fontWeight: '500' }}>
                                        {' $'}
                                        {(item.price / 100).toFixed(2)}
                                    </span>
                                )}
                                <span
                                    style={{
                                        color: item.inventory_count > 0 ? 'green' : 'red',
                                        fontStyle: 'italic',
                                        fontSize: '0.65rem',
                                    }}
                                >
                                    {item.inventory_count > 0
                                        ? ` ${item.inventory_count} available`
                                        : ' available soon'}
                                </span>
                            </Typography>
                            {item.inventory_count > 0 && (
                                <Stack sx={{ width: '20%' }}>
                                    <Select
                                        value={selectedItemCounts[item.stripe_price_id] || 0}
                                        onChange={(e: SelectChangeEvent<number>) =>
                                            handleItemCountChange(
                                                item.stripe_price_id,
                                                Number(e.target.value),
                                            )
                                        }
                                        fullWidth
                                        size="small"
                                        sx={{ fontSize: '0.8rem' }}
                                    >
                                        {[...Array(item.inventory_count + 1).keys()].map(
                                            (count) => (
                                                <MenuItem
                                                    key={count}
                                                    value={count}
                                                    sx={{ fontSize: '0.8rem' }}
                                                >
                                                    {count}
                                                </MenuItem>
                                            ),
                                        )}
                                    </Select>
                                </Stack>
                            )}
                        </Stack>
                    </FormControl>
                ))}
            </DialogContent>
            {!isPurchaseButtonDisabled() && (
                <div style={{ padding: '10px 16px 4px 0', textAlign: 'right' }}>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.65rem',
                            color: '#424242',
                            fontWeight: '450',
                        }}
                    >
                        Subtotal: ${calculateTotalCost()}
                    </Typography>
                    {!subscription && (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontSize: '0.6rem',
                                color: '#424242',
                            }}
                        >
                            Tax: (will be calculated)
                        </Typography>
                    )}
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.6rem',
                            color: '#424242',
                        }}
                    >
                        Shipping: ${shippingFee.toFixed(2)}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.65rem',
                            color: '#424242',
                            fontWeight: '600',
                        }}
                    >
                        Total: ${(parseFloat(calculateTotalCost()) + shippingFee).toFixed(2)}
                    </Typography>
                </div>
            )}
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handlePurchase}
                    variant="contained"
                    disabled={isPurchaseButtonDisabled()}
                >
                    Purchase
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PhysicalItemPurchaseDialog;
