import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import React from 'react';

const DasboardCard = ({ title, value }: { title: string; value: number }) => {
    return (
        <Paper
            sx={{
                overflow: 'hidden',
                borderRadius: 2,
                p: 6,
                boxShadow: 1,
            }}
        >
            <Typography
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '1rem',
                    fontWeight: 'medium',
                    color: 'text.secondary',
                }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    mt: 1,
                    fontSize: '2.25rem',
                    fontWeight: 'bold',
                    lineHeight: 'tight',
                    color: 'text.primary',
                }}
            >
                {value}
            </Typography>
        </Paper>
    );
};
export default DasboardCard;
