import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Button, Popover } from '@mui/material';
import React from 'react';
import ChooseOrganizationPlanRadioGroup from '../Inputs/ChooseOrganizationPlanRadioGroup';
import bronzeMedal from '../../images/bronze-medal.png';
import silverMedal from '../../images/silver-medal.png';
import goldMedal from '../../images/gold-medal (1).png';

interface ChooseOrganizationPlanPopoverProps {
    planClicked: (value: string) => void;
    disabled?: boolean;
}

export default function ChooseOrganizationPlanPopover({
    planClicked,
    disabled = false,
}: ChooseOrganizationPlanPopoverProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button onClick={handleClick} aria-describedby={id} disabled={disabled}>
                <CreditCardIcon sx={{ mr: 4 }} />
                Purchase Org Subscription
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ '& .MuiPopover-paper': { padding: 8 } }}
            >
                <ChooseOrganizationPlanRadioGroup
                    initialValue={''}
                    onSelected={(value) => {
                        planClicked(value);
                        handleClose();
                    }}
                    options={[
                        {
                            image: bronzeMedal,
                            label: 'Bronze',
                            value: process.env
                                .REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE as string,
                            description: 'GPS csv upload, 1080 API, Train, Community, Plan',
                            price: '$25',
                        },
                        {
                            image: silverMedal,
                            label: 'Silver',
                            value: process.env
                                .REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE as string,
                            description: (
                                <span>
                                    🚀 GPS API, 1080 API, Train, Community, Plan,{' '}
                                    <strong>Marketplace-Local, 🔥FiyrApp</strong>
                                </span>
                            ),
                            price: '$50',
                        },
                        {
                            image: goldMedal,
                            label: 'Gold',
                            value: process.env
                                .REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE as string,
                            description: (
                                <span>
                                    🚀 GPS API, 1080 API, Train, Community, Plan,{' '}
                                    <strong>
                                        Marketplace-Global, 🤖Unlimited Video AI, Learn, Explore,
                                        🔥FiyrApp
                                    </strong>
                                </span>
                            ),
                            price: '$100',
                        },
                    ]}
                />
            </Popover>
        </>
    );
}
