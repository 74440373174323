import { NoteAdd } from '@mui/icons-material';
import { Avatar, Box, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { AxiosResponse } from 'axios';
import LogDomain from 'domain/LogDomain';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { pushMessage } from 'redux/reducers/messages';
import { store, useAppDispatch } from 'redux/store';
import { Activity, Log, Movement, SetAttributeType } from 'utils';
import { SelectDateChip } from '../Chips';
import LogMovements from './LogMovements';

export interface EditLogFormProps {
    activity: Activity;
    activityChanged: (payload: { notes?: string; date?: string | null }) => void;
    onLogsChanged: (logs: Log[]) => void;
    onLoading: (loading: boolean) => void;
    onFinishClick: () => void;
    finishing: boolean;
}

export interface EditLogRefProps {
    onLogEnded: () => Promise<AxiosResponse> | null;
    onAllActivityLogsMarkedAsComplete: () => void;
    hasIncompleteSets: () => boolean;
    onFinishClick: () => void;
}

export interface ActivityLogSet {
    movement: Movement;
    logs: Array<Log>;
    all_marked_as_complete: boolean;
    group_title: string | null;
}

const saveLogUpdate = debounce(function (activity: Activity, logs: Array<Log>, onLoading: any) {
    onLoading(true);
    LogDomain.saveLogUpdates(activity, logs)
        .then(() => {})
        .catch((e) => {
            if (e.response.status === 422) {
                store.dispatch(
                    pushMessage({
                        status: 'error',
                        message: e.response.data.errors[Object.keys(e.response.data.errors)[0]][0],
                    }),
                );
            } else {
                store.dispatch(
                    pushMessage({
                        status: 'error',
                        message: 'Error while saving. Please check all values and try again.',
                    }),
                );
            }
        })
        .finally(() => onLoading(false));
}, 1000);

// This is the main form container component which does all the updated in the backend
const EditLogForm = React.forwardRef(
    (
        {
            activity,
            onLogsChanged,
            onFinishClick,
            finishing,
            activityChanged,
            onLoading,
        }: EditLogFormProps,
        ref,
    ) => {
        const theme = useTheme();
        const dispatch = useAppDispatch();
        const [logs, setLogs] = useState<Array<Log>>(activity.logs.filter((log) => log.movement));
        const [showNotes, setShowNotes] = useState<boolean>(false);
        const [activityLogSets, setActivityLogSets] = useState<Array<ActivityLogSet>>([]);

        const prevItemIdRef = useRef<Array<Log>>();
        useEffect(() => {
            prevItemIdRef.current = activity.logs.filter((log) => log.movement);
        });
        const previousLogs = prevItemIdRef.current;

        useEffect(() => {
            if (previousLogs) {
                saveLogUpdate(activity, logs, onLoading);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [logs]);

        useImperativeHandle(ref, () => {
            return {
                onLogEnded: () => {
                    if (logs.length == 0) {
                        dispatch(
                            pushMessage({
                                status: 'warning',
                                message: 'Empty log session can not be ended.',
                            }),
                        );
                        return Promise.reject();
                    }
                    return new Promise((resolve, reject) => {
                        LogDomain.saveLogUpdates(activity as Activity, logs)
                            ?.then((result) => {
                                if (activity.ended_at) {
                                    return resolve(result);
                                } else {
                                    return resolve(LogDomain.endActivityLog(activity as Activity));
                                }
                            })
                            .catch((error) => reject(error));
                    });
                },
                hasIncompleteSets: () => {
                    return logs.some((log) => !log.marked_as_complete);
                },
                onAllActivityLogsMarkedAsComplete: () => {
                    if (logs.length == 0) {
                        dispatch(
                            pushMessage({
                                status: 'warning',
                                message: 'No logs to mark as complete.',
                            }),
                        );
                        return;
                    }
                    LogDomain.markAllActivityLogsAsComplete(logs, setLogs);
                },
            } as EditLogRefProps;
        });

        // The frontend need the logs to be grouped together where consecutive logs for same movement form an activity log set
        // This effect hook re-groups these activity log sets when anything changes in the logs
        useEffect(() => {
            let activityLogSets: Array<ActivityLogSet> = [];

            for (let i = 0; i < logs.length; i++) {
                if (i == 0 || logs[i]?.movement?.uuid != logs[i - 1]?.movement?.uuid) {
                    activityLogSets.push({
                        movement: logs[i].movement as Movement,
                        logs: [logs[i]],
                        all_marked_as_complete: false,
                        group_title: logs[i].group_title ?? null,
                    });
                } else {
                    activityLogSets[activityLogSets.length - 1].logs.push(logs[i]);
                }
            }

            // If all logs in an activity log set are complete, we set all_marked_as_complete flag to true
            // to avoid looping again during each render
            activityLogSets = activityLogSets.map((activityLogSet) => {
                let completCount = activityLogSet.logs.filter(
                    (log) => log.marked_as_complete == true,
                ).length;
                activityLogSet.all_marked_as_complete = completCount == activityLogSet.logs.length;
                return activityLogSet;
            });

            setActivityLogSets(activityLogSets);
            onLogsChanged(logs);
        }, [logs, onLogsChanged]);

        const updateActivityLogStatus = (updatedLog: Log) => {
            LogDomain.updateActivityLogStatus(logs, updatedLog, setLogs);
        };

        const updateActivityLogValue = debounce(
            (updatedLog: Log, updatedLogCriteria: SetAttributeType, newValue: any) => {
                LogDomain.updateActivityLogValue(updatedLog, updatedLogCriteria, newValue, setLogs);
            },
        );

        // Update the log status for completion for entire group of logs belonging to an activity set
        const updateActivityLogSetStatus = (updatedLogs: Array<Log>) => {
            LogDomain.updateActivityLogSetStatus(logs, updatedLogs, setLogs);
        };

        // Add new activity log with a debounce to avoid multiple API requests when the button
        // is clicked continuously
        const addActivityLog = debounce((previousLog: Log) => {
            LogDomain.addActivityLog(activity as Activity, logs, previousLog, setLogs);
        }, 500);

        // Remove an activity log
        const removeActivityLog = (removedLog: Log) => {
            LogDomain.removeActivityLog(activity as Activity, logs, removedLog, setLogs);
        };

        // Remove multiple logs from an activity set
        const removeMultipleActivityLogs = (removedLogs: Array<Log>) => {
            LogDomain.removeMultipleActivityLogs(activity as Activity, logs, removedLogs, setLogs);
        };

        // Add criteria to the log. This is called when user adds criteria pills
        // A criteria is a set attribute like load, distance, reps etc
        const addCriteriaToActivityLog = (updatedLogs: Array<Log>, criteria: SetAttributeType) => {
            LogDomain.addCriteriaToActivityLog(logs, updatedLogs, criteria, setLogs);
        };

        // Remove criteria from the activity log set. This is called when user removes criteria pills
        // A criteria is a set attribute like load, distance, reps etc
        const removeCriteriaFromActivityLog = (
            updatedLogs: Array<Log>,
            criteria: SetAttributeType,
        ) => {
            LogDomain.removeCriteriaFromActivityLog(logs, updatedLogs, criteria, setLogs);
        };

        // Add multiple movements to a log. This is called when user chooses multip;e movements from the
        // movement selection model and adds it to the current activity log
        const onMultipleMovementsAddedToActivityLog = (addedMovements: Array<Movement>) => {
            LogDomain.onMultipleMovementsAddedToActivityLog(logs, addedMovements, setLogs);
        };

        const changeActivityLogUnit = (
            updatedLogs: Array<Log>,
            criteria: SetAttributeType,
            oldUnit: string,
            newUnit: string,
        ) => {
            LogDomain.changeActivityLogUnit(updatedLogs, criteria, oldUnit, newUnit, setLogs);
        };

        const handleDateChange = (newDate: DateTime | null) => {
            console.log(newDate);

            activityChanged({
                date: newDate ? newDate.set({ second: 0 }).toFormat('yyyy-MM-dd HH:mm:ss') : null,
            });
        };

        const startedAt = moment.utc(activity.date).format('MM/DD/YYYY');

        return (
            <Container sx={{ margin: theme.spacing(0, 'auto', 10, 'auto') }} maxWidth="md">
                <Box
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pt={8}
                >
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                        <Grid item xs={1} alignItems="center" justifyContent="center">
                            <Avatar
                                sx={{ marginTop: theme.spacing(5), variant: 'rounded' }}
                                variant="rounded"
                                src={activity.individual?.profile_photo}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textPrimary">
                                Performed by {activity.individual?.name || ''} on {startedAt}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt={3}
                >
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={8}>
                            <SelectDateChip
                                type={'datetime'}
                                value={
                                    activity.date
                                        ? DateTime.fromFormat(activity.date, 'yyyy-MM-dd HH:mm:ss')
                                        : DateTime.now()
                                }
                                onChange={handleDateChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton onClick={() => setShowNotes((show) => !show)} size="large">
                                <NoteAdd />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                {showNotes && (
                    <Box m={2}>
                        <TextField
                            name="duration"
                            variant="filled"
                            label="Add your notes here"
                            inputProps={{
                                maxLength: 10000,
                            }}
                            fullWidth
                            type="text"
                            multiline
                            rows={3}
                            defaultValue={activity?.notes || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                activityChanged({ notes: event.target.value });
                                activity.notes = event.target.value;
                            }}
                        />
                    </Box>
                )}
                {/* Movements */}
                <LogMovements
                    finishing={finishing}
                    activityLogSets={activityLogSets}
                    onMultipleMovementsAdded={onMultipleMovementsAddedToActivityLog}
                    onActivityLogStatusChanged={updateActivityLogStatus}
                    onActivityLogValueChanged={updateActivityLogValue}
                    onActivityLogAdded={addActivityLog}
                    onActivityLogRemoved={removeActivityLog}
                    onActivityLogsRemoved={removeMultipleActivityLogs}
                    onCriteriaAddedToActivityLogs={addCriteriaToActivityLog}
                    onCriteriaRemovedFromActivityLog={removeCriteriaFromActivityLog}
                    onActivityLogSetMarkedAsComplete={updateActivityLogSetStatus}
                    onActivityLogUnitChanged={changeActivityLogUnit}
                    organizationUuid={activity.session?.organization_uuid || undefined}
                    onFinishClick={onFinishClick}
                />
            </Container>
        );
    },
);

EditLogForm.displayName = 'EditLogForm';

export default EditLogForm;
