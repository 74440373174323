import React, { useEffect, useState, useRef } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGridPremium, GridSortModel } from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import { Organization } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import {
    getOrgSubscriptions,
    GetOrgSubscriptionParams,
    getUserSubscriptions,
    GetUserSubscriptionParams,
} from '../subscriptions.api';
import { OrgSubscription, UserSubscription } from '../subscriptions.types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import MenuItem from '@mui/material/MenuItem';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(25% - 16px)',
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const SubscriptionList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [orgSubscriptions, setOrgSubscriptions] = useState<OrgSubscription[]>([]);
    const [orgSearchParams, setOrgSearchParams] = useState<GetOrgSubscriptionParams>({
        name: '',
        organization_uuid: '',
        stripe_status: 'active',
        sort: '-created_at',
    });
    const [orgPaginationModel, setOrgPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [orgTotalRows, setOrgTotalRows] = useState(0);
    const orgSearchParamsRef = useRef(orgSearchParams);

    const [userSubscriptions, setUserSubscriptions] = useState<UserSubscription[]>([]);
    const [userSearchParams, setUserSearchParams] = useState<GetUserSubscriptionParams>({
        name: '',
        user_name: '',
        stripe_status: 'active',
        sort: '-created_at',
    });
    const [userPaginationModel, setUserPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [userTotalRows, setUserTotalRows] = useState(0);
    const userSearchParamsRef = useRef(userSearchParams);

    const dataGridSx = {
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
    };

    useEffect(() => {
        orgSearchParamsRef.current = orgSearchParams;
        userSearchParamsRef.current = userSearchParams;
    }, [orgSearchParams, userSearchParams]);

    useEffect(() => {
        const params = {
            ...orgSearchParamsRef.current,
            per_page: orgPaginationModel.pageSize,
            page: orgPaginationModel.page + 1,
        };
        getOrgSubscriptions(params)
            .then((response) => {
                setOrgSubscriptions(response.data.data);
                setOrgTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch subscriptions', error);
            });
    }, [orgPaginationModel]);

    useEffect(() => {
        const params = {
            ...userSearchParamsRef.current,
            per_page: userPaginationModel.pageSize,
            page: userPaginationModel.page + 1,
        };
        getUserSubscriptions(params)
            .then((response) => {
                setUserSubscriptions(response.data.data);
                setUserTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch user subscriptions', error);
            });
    }, [userPaginationModel]);

    const fetchOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchOrganizations('', 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orgColumns: GridColDef[] = [
        { field: 'organization_name', headerName: 'Organization', width: 150, sortable: true },
        { field: 'name', headerName: 'Subscription Name', width: 200, sortable: true },
        {
            field: 'stripe_id',
            headerName: 'Stripe Subscription ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<OrgSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/subscriptions/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_price',
            headerName: 'Stripe Price ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<OrgSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/prices/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: true,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            sortable: true,
        },
        {
            field: 'trial_ends_at',
            headerName: 'Trial End Date',
            width: 150,
            sortable: true,
            valueFormatter: (params: { value: string | number | Date | null }) =>
                params?.value ? new Date(params.value).toLocaleDateString() : '',
        },
        {
            field: 'ends_at',
            headerName: 'End Date',
            width: 150,
            sortable: true,
            valueFormatter: (params: { value: string | number | Date | null }) =>
                params?.value ? new Date(params.value).toLocaleDateString() : '',
        },
    ];

    const userColumns: GridColDef[] = [
        { field: 'user_name', headerName: 'User', width: 150, sortable: true },
        { field: 'name', headerName: 'Subscription Name', width: 200, sortable: true },
        {
            field: 'stripe_id',
            headerName: 'Stripe Subscription ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<UserSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/subscriptions/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_price',
            headerName: 'Stripe Price ID',
            width: 250,
            sortable: true,
            renderCell: (params: GridRenderCellParams<UserSubscription, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/prices/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: true,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            sortable: true,
        },
        {
            field: 'trial_ends_at',
            headerName: 'Trial End Date',
            width: 150,
            sortable: true,
            valueFormatter: (params: { value: string | number | Date | null }) =>
                params?.value ? new Date(params.value).toLocaleDateString() : '',
        },
        {
            field: 'ends_at',
            headerName: 'End Date',
            width: 150,
            sortable: true,
            valueFormatter: (params: { value: string | number | Date | null }) =>
                params?.value ? new Date(params.value).toLocaleDateString() : '',
        },
    ];

    const handleOrgSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setOrgSearchParams({
            ...orgSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleUserSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setUserSearchParams({
            ...userSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleOrgSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...orgSearchParams,
                organization_uuid: orgSearchParams.organization_uuid || undefined,
                page: orgPaginationModel.page + 1,
                per_page: orgPaginationModel.pageSize,
            };
            await getOrgSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const handleUserSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...userSearchParams,
                user_name: userSearchParams.user_name || undefined,
                page: userPaginationModel.page + 1,
                per_page: userPaginationModel.pageSize,
            };
            await getUserSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrgSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };
        const response = await getOrgSubscriptions(paginatedParams);
        if (Array.isArray(response.data.data)) {
            setOrgSubscriptions(response.data.data);
            setOrgTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: subscriptions data is not an array');
        }
    };

    const getUserSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: userPaginationModel.page + 1,
            per_page: userPaginationModel.pageSize,
        };
        const response = await getUserSubscriptions(paginatedParams);
        if (Array.isArray(response.data.data)) {
            setUserSubscriptions(response.data.data);
            setUserTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: user subscriptions data is not an array');
        }
    };

    const handleOrgSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...orgSearchParams,
            sort: sortField as GetOrgSubscriptionParams['sort'],
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };

        await getOrgSubscriptionSearchResults(params);
    };

    const handleUserSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...userSearchParams,
            sort: sortField as GetUserSubscriptionParams['sort'],
            page: userPaginationModel.page + 1,
            per_page: userPaginationModel.pageSize,
        };

        await getUserSubscriptionSearchResults(params);
    };

    const handleOrgSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setOrgSearchParams({
            ...orgSearchParams,
            stripe_status: event.target.value as GetOrgSubscriptionParams['stripe_status'],
        });
    };

    const handleUserSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setUserSearchParams({
            ...userSearchParams,
            stripe_status: event.target.value as GetUserSubscriptionParams['stripe_status'],
        });
    };

    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <h2>Subscriptions</h2>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter Organization Subscriptions</h4>
                <ResponsiveForm onSubmit={handleOrgSubscriptionSearch} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                organizations.find(
                                    (org) => org.uuid === orgSearchParams.organization_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setOrgSearchParams({
                                    ...orgSearchParams,
                                    organization_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={organizations}
                            getOptionLabel={(option) => `${option.name} - ${option.uuid}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Organizations" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="name"
                            label="Subscription Name"
                            value={orgSearchParams.name}
                            onChange={handleOrgSearchParameterChange}
                            placeholder="Enter Subscription Name"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControl fullWidth>
                            <InputLabel id="org-status-label">Status</InputLabel>
                            <Select
                                labelId="org-status-label"
                                size="small"
                                value={orgSearchParams.stripe_status}
                                onChange={handleOrgSelectStatusChange}
                                label="Status"
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="past_due">Past Due</MenuItem>
                                <MenuItem value="canceled">Canceled</MenuItem>
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Subscriptions
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={orgSubscriptions}
                    columns={orgColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleOrgSubscriptionSort(model)}
                    getRowId={(row) => row.id}
                    style={{ height: '624px', marginBottom: '50px' }}
                    paginationMode="server"
                    disableColumnMenu={true}
                    pagination
                    rowCount={orgTotalRows}
                    paginationModel={orgPaginationModel}
                    onPaginationModelChange={setOrgPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    sx={dataGridSx}
                />
            </Box>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter User Subscriptions</h4>
                <ResponsiveForm onSubmit={handleUserSubscriptionSearch} noValidate>
                    <ResponsiveFormItem>
                        <TextField
                            label="User Name"
                            name="user_name"
                            value={userSearchParams.user_name}
                            onChange={handleUserSearchParameterChange}
                            placeholder="Enter User Name"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            label="Subscription Name"
                            name="name"
                            value={userSearchParams.name}
                            onChange={handleUserSearchParameterChange}
                            placeholder="Enter Subscription Name"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControl fullWidth>
                            <InputLabel id="user-status-label">Status</InputLabel>
                            <Select
                                labelId="user-status-label"
                                size="small"
                                value={userSearchParams.stripe_status}
                                onChange={handleUserSelectStatusChange}
                                label="Status"
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="past_due">Past Due</MenuItem>
                                <MenuItem value="canceled">Canceled</MenuItem>
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Subscriptions
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={userSubscriptions}
                    columns={userColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleUserSubscriptionSort(model)}
                    getRowId={(row) => row.id}
                    style={{ marginBottom: '50px' }}
                    paginationMode="server"
                    pagination
                    rowCount={userTotalRows}
                    paginationModel={userPaginationModel}
                    disableColumnMenu={true}
                    onPaginationModelChange={setUserPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    sx={dataGridSx}
                />
            </Box>
        </Box>
    );
};

export default SubscriptionList;
