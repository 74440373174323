import { getAllMovements, getAllOrganizations, getAllOrganizationSubscriptions } from 'api';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/reducers/auth';
import { Movement, Organization } from 'utils';
import { Subscription } from '../app.types';

interface Props {
    organizations: Organization[];
    subscriptions: Subscription[];
    movements?: Movement[];
    loading?: boolean;
    loaded?: boolean;
}

export const OrganizationsContext = React.createContext<Props>({
    organizations: [],
    subscriptions: [],
    movements: [],
    loading: true,
    loaded: false,
});

export function OrganizationsProvider({ children }: { children: React.ReactNode }) {
    const { isAuth, organizationsLoadKey } = useSelector(authSelector);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [movements, setMovements] = useState<Movement[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!isAuth) {
            setLoading(false);
            setOrganizations([]);
            setMovements([]);
            return;
        }
        setLoading(true);
        Promise.all([getAllOrganizations(), getAllOrganizationSubscriptions(), getAllMovements()])
            .then(([response, subscriptionResponse, movementResponse]) => {
                setOrganizations(response.data);
                setSubscriptions(subscriptionResponse.data);
                if (Array.isArray(movementResponse.data) && movementResponse.data.length > 0) {
                    setMovements([movementResponse.data[0]]);
                }
                setLoaded(true);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [isAuth, organizationsLoadKey]);

    return (
        <OrganizationsContext.Provider
            value={{ organizations, subscriptions, movements, loading, loaded }}
        >
            {children}
        </OrganizationsContext.Provider>
    );
}
