import { BaseCubeMember } from '@cubejs-client/core';
import moment from 'moment';
import { Tag } from '../../app.types';
import { ChipChoice } from './components/MultiChipList';
import S3Upload from '../../utils/S3Upload';
import { DateTime } from 'luxon';
import { DataItem } from './community.types';

export function aggregateData(
    data: DataItem[],
    field: string,
    aggregateFunction: 'max' | 'min' | 'sum' | 'countNonZero',
): {
    thisWeek: number;
    last4Weeks: number;
    previous4Weeks: number;
    last6Weeks: number;
    last12Weeks: number;
    last52Weeks: number;
    previousWeek: number;
} {
    if (!Array.isArray(data) || data.length === 0) {
        throw new Error('Invalid or empty data array.');
    }

    const length = data.length;
    const aggregate = {
        max: (arr: DataItem[]) =>
            Math.max(
                ...arr.map((item) => {
                    return item[field] ? Number(item[field]) : 0;
                }),
            ),
        min: (arr: DataItem[]) =>
            Math.min(...arr.map((item) => (item[field] ? Number(item[field]) : 0))),
        sum: (arr: DataItem[]) =>
            arr.reduce((acc, item) => acc + (item[field] ? Number(item[field]) : 0), 0),
        countNonZero: (arr: DataItem[]) =>
            arr.reduce(
                (acc, item) => acc + ((item[field] ? Number(item[field]) : 0) > 0 ? 1 : 0),
                0,
            ),
    };

    const calculateAggregate = (arr: DataItem[]) => aggregate[aggregateFunction](arr);

    const thisWeek = calculateAggregate(data.slice(Math.max(0, length - 7), length));
    const previousWeek = calculateAggregate(
        data.slice(Math.max(0, length - 14), Math.max(0, length - 7)),
    );
    const last4Weeks = calculateAggregate(data.slice(Math.max(0, length - 28), length));
    const previous4Weeks = calculateAggregate(
        data.slice(Math.max(0, length - 56), Math.max(0, length - 28)),
    );
    const last6Weeks = calculateAggregate(data.slice(Math.max(0, length - 42), length));
    const last12Weeks = calculateAggregate(data.slice(Math.max(0, length - 84), length));
    const last52Weeks = calculateAggregate(data);

    return {
        thisWeek,
        last6Weeks,
        last12Weeks,
        last52Weeks,
        previousWeek,
        last4Weeks,
        previous4Weeks,
    };
}

export function mapTagIntoFreeSoloOption(t: Tag) {
    return { id: t.id, title: t.name };
}

export function displayDistanceByUnit(
    inputInMeters: number,
    unitOfMeasure: 'yd' | 'ft' | 'm',
): string {
    let returnValue = inputInMeters;

    if (unitOfMeasure === 'yd') {
        returnValue = inputInMeters * 1.09361;
    }
    if (unitOfMeasure === 'ft') {
        returnValue = inputInMeters * 3.28084;
    }
    return returnValue.toFixed(2) + unitOfMeasure;
}

export const duckDateFormatter = (label: any) => {
    const duckDate = moment(label, 'YYYY-MM-DDTHH:mm:ss.SSS', true);

    if (duckDate.isValid()) {
        return `${duckDate.format('MMM Do, YYYY')}`;
    }
    return label;
};

export const cubeMemberFormatter = (member: BaseCubeMember) => {
    return member;
};

export function mapTagsIntoChipChoices(tags: Tag[]): Array<ChipChoice> {
    return tags.map((t) => ({
        label: t.name,
        id: t.id,
        isDeleteable: true,
    }));
}

export function mapTagIntoChipChoice(tag: Tag): ChipChoice {
    return { label: tag.name, id: tag.id, isDeleteable: true };
}

export const uploadToS3 = (
    file: File,
    accessToken: string,
    onComplete: (fileName: string, awsObjectKey: string) => void,
    onError: () => void,
    onProgress: (fileName: string, originalFileName: string, number: number) => void,
    onStarted: () => void,
) => {
    const uploader = new S3Upload();
    uploader.onStarted = onStarted;
    uploader.onBeforeSigner = (fileName, xhr) => {
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    };
    uploader.onComplete = onComplete;
    uploader.onError = onError;
    uploader.onProgress = onProgress;
    uploader.uploadToS3(file as File);
};

export function formatNumber(num: number): string {
    if (!num) {
        return '-';
    }
    return num.toLocaleString('en-US');
}

export function formatDateStringLuxon(dateStr: string): string {
    if (!dateStr) {
        return '';
    }
    let date = DateTime.fromISO(dateStr);
    if (!date.isValid) {
        return '';
    }
    return date.toFormat('MM/dd/yyyy');
}

export function formatTimestampTenths(timestamp: string): string {
    if (!timestamp) {
        return '';
    }
    let date = DateTime.fromMillis(+timestamp);

    if (!date.isValid) {
        return '';
    }

    return date.toFormat('h:mm:ss.S a');
}

export function formatTimestampSeconds(timestamp: string): string {
    if (!timestamp) {
        return '';
    }
    let date = DateTime.fromMillis(+timestamp);

    if (!date.isValid) {
        return '';
    }

    return date.toFormat('h:mm:ss a');
}
