import React, { useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField, Typography } from '@mui/material';
import { MarketplaceItem } from '../../app.types';
import { updateItem } from '../../api/marketplace.api';
import { FormModal } from '../Dialogs';

const EditMarketplaceItem = ({
    open,
    handleClose,
    item,
    onSaved,
    onSaveError,
}: {
    open: boolean;
    handleClose: () => void;
    item: MarketplaceItem;
    onSaved: (item: MarketplaceItem) => void;
    onSaveError: () => void;
}) => {
    const saveItem = useCallback(
        (values: { price: string }) => {
            // convert price into a number of cents
            const payload = {
                price: Math.round(parseFloat(values.price) * 100),
            } as any;

            updateItem(item.organization_uuid, item.uuid, payload)
                .then((result) => {
                    onSaved(result.data);
                    handleClose();
                })
                .catch(() => {
                    onSaveError();
                });
        },
        [item, onSaved, handleClose, onSaveError],
    );

    const { values, errors, handleChange } = useFormik({
        initialValues: {
            price: (item.price / 100).toFixed(2),
        },
        validationSchema: yup.object({
            price: yup.string().required('Price is required'),
        }),
        onSubmit: () => {},
    });
    return (
        <FormModal
            handleClose={handleClose}
            open={open}
            handleButtonClicked={() => saveItem(values)}
            disabled={Boolean(errors.price)}
        >
            <form
                onSubmit={(e) => {
                    saveItem(values);
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Stack spacing={8}>
                    <Typography>Enter a price</Typography>
                    <TextField
                        autoFocus={true}
                        id="price"
                        name="price"
                        inputProps={{
                            pattern: '[-.0-9]*',
                            inputMode: 'numeric',
                            type: 'text',
                        }}
                        placeholder="0.00"
                        label="Price"
                        value={values.price}
                        onChange={handleChange}
                        error={!!errors.price}
                        helperText={errors.price as string}
                    />
                </Stack>
            </form>
        </FormModal>
    );
};

export default EditMarketplaceItem;
