import { Box, Button, Container, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getHtml, Organization } from '../../../utils';
import MarketplaceItem from '../marketplace.types';
import { viewOrganization } from '../../../api';
import { createMarketplaceCheckoutSession, getMarketplaceItem } from '../marketplace.api';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const MarketplaceHome = () => {
    const [organization, setOrganization] = React.useState<Organization>();
    const [item, setItem] = React.useState<MarketplaceItem>();
    const [message, setMessage] = React.useState<string>('');
    const [creatingCheckout, setCreatingCheckout] = React.useState(false);

    const { id } = useParams<{ id: string }>();
    const { itemId } = useParams<{ itemId: string }>();

    useEffect(() => {
        const fetchOrganization = async () => {
            try {
                const { data } = await viewOrganization(id);
                setOrganization(data);
            } catch (e) {
                setMessage('Error fetching organization details');
            }
        };

        const fetchItem = async () => {
            try {
                const { data } = await getMarketplaceItem(id, itemId);
                setItem(data);
            } catch (e) {
                setMessage('Error fetching item for sale');
            }
        };

        fetchItem();
        fetchOrganization();
    }, [id, itemId]);
    const history = useHistory();
    return (
        <main>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!message}
                autoHideDuration={6000}
                message={message}
                onClose={() => setMessage('')}
            />
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                    position: 'relative',
                }}
            >
                <IconButton
                    onClick={history.goBack}
                    size="large"
                    sx={{
                        position: 'absolute', // Position the button absolutely
                        left: 0, // Align it to the left edge
                        top: '50%', // Align vertically
                        transform: 'translateY(-50%)', // Center vertically
                    }}
                >
                    <ArrowBackIosIcon color="inherit" />
                </IconButton>
                <Container maxWidth="sm">
                    {organization && (
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            {organization.name}&lsquo;s Marketplace
                        </Typography>
                    )}

                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        Buy premium training templates and assessments to use for your organization.
                    </Typography>
                </Container>
            </Box>
            <Container sx={{ py: 8 }} maxWidth="md">
                {item && (
                    <Stack spacing={4}>
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            {item.title}
                        </Typography>

                        <div
                            dangerouslySetInnerHTML={{
                                __html: getHtml(item.description || ''),
                            }}
                        />

                        {item.imageUrl && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <img src={item.imageUrl} alt={item.title} />
                            </Box>
                        )}
                        {organization && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={creatingCheckout}
                                    onClick={async () => {
                                        // create a stripe checkout session
                                        // redirect to the checkout page
                                        setCreatingCheckout(true);
                                        try {
                                            const response = await createMarketplaceCheckoutSession(
                                                item.uuid,
                                                organization.uuid,
                                            );
                                            // redirect to the checkout page
                                            window.location.href = response.data.session.url;
                                        } catch (e) {
                                            setMessage('Error creating checkout session');
                                        } finally {
                                            setCreatingCheckout(false);
                                        }
                                    }}
                                >
                                    Purchase
                                </Button>
                            </Box>
                        )}
                    </Stack>
                )}
            </Container>
        </main>
    );
};

export default MarketplaceHome;
