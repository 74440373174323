import NavigationLayout from '../../Layouts/NavigationLayout';
import { Navigation } from '../../utils';
import {
    AssessmentDashboard,
    AssessmentListDashboard,
    AssignAssessmentToIndividual,
    CompleteAssessment,
    CompleteAssessmentPreview,
    StartForSelf,
    SubmissionResult,
    ViewAssessment,
} from './scenes';
import CreateAssessment from './scenes/CreateAssessment';

export const learnRoutes = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class',
        component: AssessmentDashboard, //  <-- Scene
        private: true, // <-- If not logged in, redirect to the login screen
        primaryNavigation: Navigation.LEARN, // <-- Which tab is highlighted
        secondaryNavigation: Navigation.LEARN_DASHBOARD, // <-- Which tab is highlighted
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/assessments',
        component: AssessmentListDashboard,
        private: true,
        primaryNavigation: Navigation.LEARN,
        secondaryNavigation: Navigation.LEARN_ASSESSMENTS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/assessments/:id/view',
        component: ViewAssessment,
        private: true,
        primaryNavigation: Navigation.LEARN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/assessments/:assessmentId/assign/:individualId',
        component: AssignAssessmentToIndividual,
        private: true,
        primaryNavigation: Navigation.LEARN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/assessments/:assessmentId/assign-group/:groupId',
        component: AssignAssessmentToIndividual,
        private: true,
        primaryNavigation: Navigation.LEARN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/submissions/:id',
        component: CompleteAssessment,
        private: true,
        primaryNavigation: Navigation.LEARN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/assessments/preview/:id',
        private: true,
        component: CompleteAssessmentPreview,
        primaryNavigation: Navigation.LEARN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/class/assessments/:id',
        component: CreateAssessment,
        private: true,
        primaryNavigation: Navigation.LEARN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/assessments/:id',
        component: SubmissionResult,
        private: true,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/assessments/:id/start-self',
        private: true,
        component: StartForSelf,
        primaryNavigation: Navigation.LEARN,
    },
];
