import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getUserPods,
    getUserSubscriptions,
    userIsLoadedSelector,
    userPodsIsLoadedSelector,
    userPodsSelector,
    userSubscriptionsIsLoadedSelector,
    userSubscriptionsSelector,
} from 'redux/reducers/user';
import { AthletePod, AthleteSubscription } from '../app.types';
import { useAppDispatch } from '../redux/store';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
    subscriptions: AthleteSubscription[];
    subscriptionsLoaded: boolean;
    userPods: AthletePod[];
    userPodsIsLoaded: boolean;
    isModalOpen: boolean;
    closeModal: () => void;
}

export const AthleteContext = React.createContext<Props>({
    subscriptions: [],
    subscriptionsLoaded: false,
    userPods: [],
    userPodsIsLoaded: false,
    isModalOpen: false,
    closeModal: () => {},
});

export function AthleteSubscriptionsProvider({ children }: { children: React.ReactNode }) {
    const subscriptions = useSelector(userSubscriptionsSelector);
    const subscriptionsLoaded = useSelector(userSubscriptionsIsLoadedSelector);
    const userLoaded = useSelector(userIsLoadedSelector);
    const userSubscriptionsIsLoaded = useSelector(userSubscriptionsIsLoadedSelector);
    const userPods = useSelector(userPodsSelector);
    const userPodsIsLoaded = useSelector(userPodsIsLoadedSelector);
    const dispatch = useAppDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalClosedInSession, setIsModalClosedInSession] = useState(false);
    const [hasDefaultSubscription, setHasDefaultSubscription] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('1Tul Subscription Required');
    const [dialogContent, setDialogContent] = useState(
        'You will need to sign up for a 1Tul subscription. Please see your marketplaces and choose one from which to purchase a subscription.',
    );
    const location = useLocation();

    const closeModal = () => {
        setIsModalOpen(false);
        setIsModalClosedInSession(true);
    };
    const history = useHistory();

    const ModalComponent = () => (
        <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        history.push('/athletes');
                        closeModal();
                    }}
                >
                    View My Marketplaces
                </Button>
                <Button variant="outlined" onClick={closeModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    useEffect(() => {
        if (userLoaded && !userSubscriptionsIsLoaded) {
            dispatch(getUserSubscriptions()).then((subscriptions: any) => {
                if (
                    subscriptions.payload.some((subscription: any) => {
                        return subscription.name === 'default';
                    })
                ) {
                    setHasDefaultSubscription(true);
                } else {
                    setHasDefaultSubscription(false);

                    if (!location.pathname.includes('/athletes')) {
                        // setIsModalOpen(true);
                    }
                }
            });
        }

        if (userLoaded && hasDefaultSubscription && !userPodsIsLoaded) {
            dispatch(getUserPods()).then((pods: any) => {
                if (pods.payload?.length > 0) {
                    /**
                     * To display a pod transfer pending modal notification, there must be at least one pod
                     * that has a null transfer_request_accepted_at and there must be no pods
                     * where transfer_request_accepted_at is not null.
                     */
                    const hasPendingPodTransferRequest =
                        pods.payload.some(
                            (pod: any) => pod.transfer_request_accepted_at === null,
                        ) &&
                        !pods.payload.some((pod: any) => pod.transfer_request_accepted_at !== null);
                    if (
                        hasPendingPodTransferRequest &&
                        !isModalClosedInSession &&
                        !location.pathname.includes('/athletes')
                    ) {
                        console.debug('Pod transfer request(s) found');
                        setDialogTitle('Pod Transfer Request');
                        setDialogContent(
                            'You have a FiyrPod transfer request. Please see your marketplaces and choose one from which to purchase a subscription.',
                        );
                        setIsModalOpen(true);
                    }
                }
            });
        }
    }, [
        location.pathname,
        userLoaded,
        userSubscriptionsIsLoaded,
        dispatch,
        userPodsIsLoaded,
        userPods,
        isModalClosedInSession,
        hasDefaultSubscription,
    ]);

    return (
        <AthleteContext.Provider
            value={{
                subscriptions,
                subscriptionsLoaded,
                userPods,
                userPodsIsLoaded,
                isModalOpen,
                closeModal,
            }}
        >
            {children}
            <ModalComponent />
        </AthleteContext.Provider>
    );
}
