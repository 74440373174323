import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import MarketplaceItem from './marketplace.types';

interface StripeCheckoutSession {
    id: string;
    url: string;
}

export const getLocalMarketplaceItems = (
    organizationUuid: string,
    filters: Record<string, any>,
): Promise<AxiosResponse<MarketplaceItem[]>> => {
    return Request.get(`/public/marketplace/${organizationUuid}/items`, { params: filters });
};

export const getMarketplaceItem = (
    organizationUuid: string,
    itemUuid: string,
): Promise<AxiosResponse<MarketplaceItem>> => {
    return Request.get(`/public/marketplace/${organizationUuid}/items/${itemUuid}`);
};

export const createMarketplaceCheckoutSession = (
    itemUuid: string,
    buyerOrganizationUuid: string,
): Promise<AxiosResponse<{ session: StripeCheckoutSession }>> => {
    return Request.post(`/public/marketplace/start-checking-out`, {
        itemUuid,
        buyerOrganizationUuid,
    });
};

export const getPodColors = (): Promise<AxiosResponse<string[]>> => {
    return Request.get(`/public/marketplace/pod-colors`);
};
