import React, { useCallback, useRef, useState } from 'react';
import { Box, Button, ButtonBase, Modal, Theme, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useToggle } from 'utils';
import { useTheme } from '@mui/system';

import CropImageModal from 'components/Dialogs/CropImageModal';
import { ButtonWithLoader } from 'components/Buttons';
import { SxProps } from '@mui/system';

export interface UploadLogoProps {
    loading?: boolean;
    uploadImage: File | undefined;
    setUploadImage: React.Dispatch<React.SetStateAction<File | undefined>>;
    defaultImgSrc?: string | null;
    button?: boolean;
    uploadContainerClassname?: string;
    uploadedImageClassname?: string;
    imageName?: 'logo' | 'profile' | 'cover photo';
    onClose?: (file?: File) => void; // either this function set cropped file or it set complete file as logo
    uploadContainerSx?: SxProps<Theme>;
    uploadedImageSx?: SxProps<Theme>;
    deleteLogo?: () => void;
    onMessage?: (message: string) => void;
}

const UploadLogo = ({
    loading,
    uploadImage,
    setUploadImage,
    defaultImgSrc = null,
    button = true,
    uploadContainerClassname,
    uploadedImageClassname,
    imageName = 'logo',
    onClose,
    deleteLogo = () => {},
    onMessage = () => {},
}: UploadLogoProps) => {
    const theme = useTheme();
    const [visible, toggle] = useToggle();
    const [isLogoDeleted, setIsLogoDeleted] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const setOriginalImage = useCallback(
        (file: File) => {
            setUploadImage(file);
            toggle();
        },
        [setUploadImage, toggle],
    );

    const setCroppedImage = useCallback(
        (file: File) => {
            setUploadImage(file);
            onClose && onClose(file);
            toggle();
        },
        [setUploadImage, toggle, onClose],
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                {(uploadImage || defaultImgSrc) && !isLogoDeleted && (
                    <Button
                        sx={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            cursor: 'pointer',
                            padding: '1px',
                            color: 'white',
                            height: '32px',
                            width: '32px',
                            minWidth: '32px',
                            background: 'rgba(0,0,0,0.3)',
                            zIndex: 9999,
                            borderRadius: '2px',
                            '&:hover': {
                                background: 'rgba(0,0,0,0.9)',
                            },
                        }}
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        X
                    </Button>
                )}
                <ButtonBase
                    sx={{
                        position: 'relative',
                        width: 144,
                        height: 144,
                        display: 'flex',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        m: 6,
                        border:
                            (!uploadImage && !defaultImgSrc) || isLogoDeleted
                                ? '2px dashed rgba(87, 95, 107, 0.5)'
                                : '1px solid rgba(87, 95, 107, 0.5)',
                        borderRadius: 15,
                        background:
                            'linear-gradient(0deg, rgba(0, 62, 157, 0.08), rgba(0, 62, 157, 0.08)), #FFFFFF',
                        ...(uploadContainerClassname === undefined &&
                            {
                                // your styles when uploadContainerClassname isn't provided
                            }),
                    }}
                    onClick={() => fileInputRef.current && fileInputRef.current?.click()}
                >
                    {uploadImage && !isLogoDeleted && (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                ...(uploadedImageClassname === undefined &&
                                    {
                                        // your styles here, applied when uploadedImageClassname is not given
                                    }),
                            }}
                            src={URL.createObjectURL(uploadImage)}
                            alt={imageName}
                        />
                    )}
                    {!uploadImage && defaultImgSrc && !isLogoDeleted && (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                ...(uploadedImageClassname === undefined &&
                                    {
                                        // your styles here, applied when uploadedImageClassname is not given
                                    }),
                            }}
                            src={defaultImgSrc}
                            alt={imageName}
                        />
                    )}

                    {((!uploadImage && !defaultImgSrc) || isLogoDeleted) && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AddOutlinedIcon
                                sx={{ fontSize: 24, fill: theme.palette.primary.light }}
                            />
                            <Box>Add Logo</Box>
                        </Box>
                    )}
                </ButtonBase>
                <div>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const files = e.target.files;
                            if (files && files.length > 0) setOriginalImage(files[0]);
                        }}
                        onClick={(event) => {
                            if (event.currentTarget.value) event.currentTarget.value = '';
                        }}
                        hidden={true}
                    />
                </div>

                {button && (uploadImage || defaultImgSrc) && (
                    <ButtonWithLoader
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        sx={{ minWidth: 192, minHeight: 56 }}
                        onClick={() => fileInputRef.current?.click()}
                        variant={'outlined'}
                    >
                        {`Replace ${imageName}`}
                    </ButtonWithLoader>
                )}
                {!!uploadImage && (
                    <CropImageModal
                        isOpen={visible}
                        close={() => {
                            onClose && onClose();
                            toggle();
                        }}
                        uploadImage={uploadImage ? URL.createObjectURL(uploadImage) : ''}
                        buttonText="Save"
                        onComplete={(image) => {
                            setCroppedImage(image);
                            setIsLogoDeleted(false);
                        }}
                    />
                )}
            </Box>
            <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <Box
                    sx={{
                        padding: 8,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 'auto',
                        background: 'white',
                        color: 'black',
                        height: '25%',
                        width: '25%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Typography>Are you sure you want to delete this logo?</Typography>
                    <Box
                        sx={{
                            marginTop: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ marginRight: 2 }}
                            onClick={async () => {
                                deleteLogo();
                                setIsLogoDeleted(true);
                                setIsDeleteModalOpen(false);
                                setUploadImage(undefined);
                                onMessage('Logo deleted successfully!');
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setIsDeleteModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export { UploadLogo };
