import { Request } from 'api';
import { AxiosResponse } from 'axios';
import {
    UpdateCommissionParams,
    GetCommissionsParams,
    CreateCommissionParams,
    CommissionBonusType,
} from './commissions.types';
import { Commission } from '../../../networking/networking.types';

interface PaginatedApiResponse {
    data: Array<Commission>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

const getCommissionsForAdmins = (params?: GetCommissionsParams): ReturnTypeCollection => {
    console.log(params);
    const filterParams = {
        'filter[type]': params?.type,
        'filter[earned_date]': params?.earned_date,
        'filter[is_paid]': params?.is_paid,
        'filter[payout_uuid]': params?.payout_uuid,
        'filter[partner.organization]': params?.['partner.organization'],
        'filter[otherPartner.organization]': params?.['otherPartner.organization'],
        'filter[athlete_user_uuid]': params?.athlete_user_uuid,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/commissions`, { params: filterParams });
};

type ReturnTypeSingle = Promise<AxiosResponse<Commission>>;

const updateCommission = (
    commissionUuid: string,
    commissionData: UpdateCommissionParams,
): ReturnTypeSingle => {
    return Request.put(`admin/commissions/${commissionUuid}`, commissionData);
};

type ReturnTypeCommissionTypes = Promise<AxiosResponse<Array<string>>>;

const getCommissionTypesForAdmin = (): ReturnTypeCommissionTypes => {
    return Request.get(`admin/commissions/types`);
};

type ReturnTypeCommissionBonusTypes = Promise<AxiosResponse<Array<CommissionBonusType>>>;

const getCommissionBonusTypesForAdmin = (): ReturnTypeCommissionBonusTypes => {
    return Request.get(`admin/commissions/bonus-types`);
};

const adminCreateNewCommission = (commissionData: CreateCommissionParams): ReturnTypeSingle => {
    return Request.post(`admin/commissions`, commissionData);
};

export {
    updateCommission,
    getCommissionsForAdmins,
    getCommissionTypesForAdmin,
    getCommissionBonusTypesForAdmin,
    adminCreateNewCommission,
};
export type { UpdateCommissionParams, GetCommissionsParams, CreateCommissionParams };
