import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { DataGridPremium, GridSortModel } from '@mui/x-data-grid-premium';

import React, { useEffect, useState, useRef } from 'react';
import {
    getOrgSubscriptions,
    GetOrgSubscriptionParams,
    //GetUserSubscriptionParams,
    //getUserSubscriptions,
} from '../networking.api';
import { OrgSubscription } from '../networking.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import MenuItem from '@mui/material/MenuItem';
//import { UserSubscription } from '../../admin/modules/subscriptions/subscriptions.types';

const SubscriptionScene = () => {
    const [orgSubscriptions, setOrgSubscriptions] = useState<OrgSubscription[]>([]);
    const [orgSearchParams, setOrgSearchParams] = useState<GetOrgSubscriptionParams>({
        name: '',
        organization_uuid: '',
        level: '0',
        stripe_status: 'past_due',
        sort: '-created_at',
    });
    const [orgPaginationModel, setOrgPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [orgTotalRows, setOrgTotalRows] = useState(0);
    const orgSearchParamsRef = useRef(orgSearchParams);

    //const [userSubscriptions, setUserSubscriptions] = useState<UserSubscription[]>([]);
    // const [userSearchParams, setUserSearchParams] = useState<GetUserSubscriptionParams>({
    //     name: '',
    //     user_name: '',
    //     level: '0',
    //     stripe_status: 'past_due',
    //     sort: '-user_subscriptions.created_at',
    // });
    // const [userPaginationModel, setUserPaginationModel] = useState({
    //     page: 0,
    //     pageSize: 50,
    // });
    //const [userTotalRows, setUserTotalRows] = useState(0);
    //const userSearchParamsRef = useRef(userSearchParams);

    const organization = useNetworkingOrganization();

    const dataGridSx = {
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
    };

    useEffect(() => {
        orgSearchParamsRef.current = orgSearchParams;
        //userSearchParamsRef.current = userSearchParams;
    }, [orgSearchParams /** userSearchParams **/]);

    useEffect(() => {
        const params = {
            ...orgSearchParamsRef.current,
            per_page: orgPaginationModel.pageSize,
            page: orgPaginationModel.page + 1,
        };
        getOrgSubscriptions(organization?.data?.uuid as string, params)
            .then((response) => {
                setOrgSubscriptions(response.data.data);
                setOrgTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch subscriptions', error);
            });
    }, [organization?.data, orgPaginationModel]);

    // useEffect(() => {
    //     const params = {
    //         ...userSearchParamsRef.current,
    //         per_page: userPaginationModel.pageSize,
    //         page: userPaginationModel.page + 1,
    //     };
    //     getUserSubscriptions(organization?.data?.uuid as string, params)
    //         .then((response) => {
    //             setUserSubscriptions(response.data.data);
    //             setUserTotalRows(response.data.meta.total);
    //         })
    //         .catch((error) => {
    //             console.error('Failed to fetch subscriptions', error);
    //         });
    // }, [organization?.data, userPaginationModel]);

    const orgColumns: GridColDef[] = [
        { field: 'organization_name', headerName: 'Organization', width: 200, sortable: false },
        { field: 'name', headerName: 'Subscription Name', width: 250, sortable: true },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: true,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            sortable: true,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 150,
            sortable: true,
            renderCell: (params) => {
                return new Date(params.value as string).toLocaleDateString();
            },
        },
    ];

    // const userColumns: GridColDef[] = [
    //     { field: 'user_name', headerName: 'User Name', width: 150, sortable: true },
    //     {
    //         field: 'name',
    //         headerName: 'Subscription Name',
    //         width: 200,
    //         sortable: true,
    //     },
    //     {
    //         field: 'stripe_status',
    //         headerName: 'Status',
    //         width: 150,
    //         sortable: true,
    //     },
    //     {
    //         field: 'quantity',
    //         headerName: 'Quantity',
    //         width: 100,
    //         sortable: true,
    //     },
    // ];

    const handleOrgSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setOrgSearchParams({
            ...orgSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleOrgSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...orgSearchParams,
                organization_uuid: orgSearchParams.organization_uuid || undefined,
                page: orgPaginationModel.page + 1,
                per_page: orgPaginationModel.pageSize,
            };
            await getOrgSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrgSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };
        const response = await getOrgSubscriptions(
            organization?.data?.uuid as string,
            paginatedParams,
        );
        if (Array.isArray(response.data.data)) {
            setOrgSubscriptions(response.data.data);
            setOrgTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: subscriptions data is not an array');
        }
    };

    const handleOrgSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...orgSearchParams,
            sort: sortField as GetOrgSubscriptionParams['sort'],
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };

        await getOrgSubscriptionSearchResults(params);
    };

    const handleOrgSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setOrgSearchParams({
            ...orgSearchParams,
            stripe_status: event.target.value,
        });
    };

    const handleOrgSelectLevelChange = (event: SelectChangeEvent<string>) => {
        setOrgSearchParams({
            ...orgSearchParams,
            level: event.target.value,
        });
    };

    // const handleUserSearchParameterChange = (
    //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    // ) => {
    //     setUserSearchParams({
    //         ...userSearchParams,
    //         [event.target.name as string]: event.target.value,
    //     });
    // };

    // const handleUserSubscriptionSearch = async (event: React.FormEvent) => {
    //     event.preventDefault();
    //     try {
    //         const params = {
    //             ...userSearchParams,
    //             user_name: userSearchParams.user_name || undefined,
    //             page: userPaginationModel.page + 1,
    //             per_page: userPaginationModel.pageSize,
    //         };
    //         await getUserSubscriptionSearchResults(params);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // const getUserSubscriptionSearchResults = async (params: any) => {
    //     const paginatedParams = {
    //         ...params,
    //         page: userPaginationModel.page + 1,
    //         per_page: userPaginationModel.pageSize,
    //     };
    //     const response = await getUserSubscriptions(
    //         organization?.data?.uuid as string,
    //         paginatedParams,
    //     );
    //     if (Array.isArray(response.data.data)) {
    //         setUserSubscriptions(response.data.data);
    //         setUserTotalRows(response.data.meta.total);
    //     } else {
    //         console.error('Unexpected data format: subscriptions data is not an array');
    //     }
    // };

    // const handleUserSubscriptionSort = async (model: GridSortModel) => {
    //     let sortField = model[0]?.field;
    //     const sortOrder = model[0]?.sort;
    //
    //     if (sortField && sortOrder === 'desc') {
    //         sortField = `-user_subscriptions.${sortField}`;
    //     }
    //
    //     const params = {
    //         ...userSearchParams,
    //         sort: sortField as GetUserSubscriptionParams['sort'],
    //         page: userPaginationModel.page + 1,
    //         per_page: userPaginationModel.pageSize,
    //     };
    //
    //     await getUserSubscriptionSearchResults(params);
    // };

    // const handleUserSelectStatusChange = (event: SelectChangeEvent<string>) => {
    //     setUserSearchParams({
    //         ...userSearchParams,
    //         stripe_status: event.target.value,
    //     });
    // };
    //
    // const handleUserSelectLevelChange = (event: SelectChangeEvent<string>) => {
    //     setUserSearchParams({
    //         ...userSearchParams,
    //         level: event.target.value,
    //     });
    // };

    return (
        <Box style={{ marginLeft: '2rem' }}>
            <h2>Subscriptions</h2>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter Organization Subscriptions</h4>
                <form onSubmit={handleOrgSubscriptionSearch} noValidate>
                    <FormControl>
                        <TextField
                            name="name"
                            label="Subcription Name"
                            value={orgSearchParams.name}
                            onChange={handleOrgSearchParameterChange}
                            placeholder="Enter Subscription Name"
                            size={'small'}
                            sx={{ marginRight: '2px' }}
                        />
                    </FormControl>
                    <FormControl sx={{ marginRight: '4px' }}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            size="small"
                            value={orgSearchParams.stripe_status}
                            onChange={handleOrgSelectStatusChange}
                            label="Status"
                        >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="past_due">Past Due</MenuItem>
                            <MenuItem value="canceled">Canceled</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ marginRight: '4px' }}>
                        <InputLabel id="status-label">Level</InputLabel>
                        <Select
                            size="small"
                            value={orgSearchParams.level}
                            onChange={handleOrgSelectLevelChange}
                            label="Level"
                        >
                            <MenuItem value="0">My Subscriptions</MenuItem>
                            <MenuItem value="1">Level 1 Partners</MenuItem>
                        </Select>
                    </FormControl>
                    <Button type="submit" size={'medium'} variant="contained">
                        Search Subscriptions
                    </Button>
                </form>
            </section>
            <Box width="80%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={orgSubscriptions}
                    columns={orgColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleOrgSubscriptionSort(model)}
                    getRowId={(row) => row.id}
                    style={{ height: '624px', marginBottom: '50px' }}
                    paginationMode="server"
                    pagination
                    rowCount={orgTotalRows}
                    paginationModel={orgPaginationModel}
                    onPaginationModelChange={setOrgPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    sx={dataGridSx}
                    disableColumnMenu={true}
                />
            </Box>
            {/*<section style={{ marginBottom: '2rem' }}>*/}
            {/*    <h4>Filter User Subscriptions</h4>*/}
            {/*    <form onSubmit={handleUserSubscriptionSearch} noValidate>*/}
            {/*        <FormControl>*/}
            {/*            <TextField*/}
            {/*                name="name"*/}
            {/*                label="User Name"*/}
            {/*                value={userSearchParams.user_name}*/}
            {/*                onChange={handleUserSearchParameterChange}*/}
            {/*                placeholder="Enter User Name"*/}
            {/*                size={'small'}*/}
            {/*                sx={{ marginRight: '2px' }}*/}
            {/*            />*/}
            {/*        </FormControl>*/}
            {/*        <FormControl>*/}
            {/*            <TextField*/}
            {/*                name="name"*/}
            {/*                label="Subcription Name"*/}
            {/*                value={userSearchParams.name}*/}
            {/*                onChange={handleUserSearchParameterChange}*/}
            {/*                placeholder="Enter Subscription Name"*/}
            {/*                size={'small'}*/}
            {/*                sx={{ marginRight: '2px' }}*/}
            {/*            />*/}
            {/*        </FormControl>*/}
            {/*        <FormControl sx={{ marginRight: '4px' }}>*/}
            {/*            <InputLabel id="status-label">Status</InputLabel>*/}
            {/*            <Select*/}
            {/*                size="small"*/}
            {/*                value={userSearchParams.stripe_status}*/}
            {/*                onChange={handleUserSelectStatusChange}*/}
            {/*                label="Status"*/}
            {/*            >*/}
            {/*                <MenuItem value="active">Active</MenuItem>*/}
            {/*                <MenuItem value="past_due">Past Due</MenuItem>*/}
            {/*                <MenuItem value="canceled">Canceled</MenuItem>*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*        <FormControl sx={{ marginRight: '4px' }}>*/}
            {/*            <InputLabel id="status-label">Level</InputLabel>*/}
            {/*            <Select*/}
            {/*                size="small"*/}
            {/*                value={userSearchParams.level}*/}
            {/*                onChange={handleUserSelectLevelChange}*/}
            {/*                label="Level"*/}
            {/*            >*/}
            {/*                <MenuItem value="0">My User Subscriptions</MenuItem>*/}
            {/*                <MenuItem value="1">Level 1 Partners</MenuItem>*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*        <Button type="submit" size={'medium'} variant="contained">*/}
            {/*            Search Subscriptions*/}
            {/*        </Button>*/}
            {/*    </form>*/}
            {/*</section>*/}
            {/*<Box width="80%" maxHeight="600px" overflow="auto" className="dataGridContainer">*/}
            {/*    <DataGridPremium*/}
            {/*        rows={userSubscriptions}*/}
            {/*        columns={userColumns}*/}
            {/*        checkboxSelection={false}*/}
            {/*        sortingMode="server"*/}
            {/*        onSortModelChange={(model) => handleUserSubscriptionSort(model)}*/}
            {/*        getRowId={(row) => row.id}*/}
            {/*        style={{ height: '624px', marginBottom: '50px' }}*/}
            {/*        paginationMode="server"*/}
            {/*        pagination*/}
            {/*        rowCount={userTotalRows}*/}
            {/*        paginationModel={userPaginationModel}*/}
            {/*        onPaginationModelChange={setUserPaginationModel}*/}
            {/*        pageSizeOptions={[5, 10, 25, 50, 100]}*/}
            {/*        sx={dataGridSx}*/}
            {/*    />*/}
            {/*</Box>*/}
        </Box>
    );
};

export default SubscriptionScene;
