import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation, Redirect, Switch, Route } from 'react-router-dom';
import { Tabs, Tab, Container, AppBar, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { UseAdminStatus } from '../../contexts/AdminContext';
import OrdersList from './modules/orders/scenes/OrdersList';
import PodList from './modules/pods/scenes/PodList';
import PhysicalProductList from './modules/physicalProducts/scenes/PhysicalProductList';
import SubscriptionList from './modules/subscriptions/scenes/SubscriptionList';
import CommissionList from './modules/commissions/scenes/CommissionList';
import DescriptionIcon from '@mui/icons-material/Description';
import EggIcon from '@mui/icons-material/Egg';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledTab = styled(Tab)(({ theme }) => ({
    width: '20%', // Set default width to 20% (100% / 5 tabs)
    minWidth: 'auto',
    minHeight: '40px', // Keep the minimum height
    height: '40px', // Keep the fixed height
    fontSize: '0.6rem', // Reduced font size for the text
    padding: '10px 8px 4px', // Add more top padding to lower the content
    '& .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row', // Ensure icon and text are in a row
        justifyContent: 'center',
        alignItems: 'center', // Center items vertically
        height: '100%', // Full height of the tab
    },
    '& .MuiTab-iconWrapper': {
        marginBottom: '2px', // Set the margin-bottom to 2px as requested
        marginRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.2rem', // Base size for the icon
    },
}));

export default function AdminBasePage(): ReactElement {
    const history = useHistory();
    const location = useLocation();
    const isAdmin = UseAdminStatus();
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if (location.pathname === '/admin') {
            history.replace('/admin/orders');
        }
    }, [location.pathname, history]);

    useEffect(() => {
        if (location.pathname.includes('/admin/orders')) setTabValue(0);
        else if (location.pathname.includes('/admin/pods')) setTabValue(1);
        else if (location.pathname.includes('/admin/products')) setTabValue(2);
        else if (location.pathname.includes('/admin/subscriptions')) setTabValue(3);
        else if (location.pathname.includes('/admin/commissions')) setTabValue(4);
        else setTabValue(0);
    }, [location.pathname]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const paths = ['orders', 'pods', 'products', 'subscriptions', 'commissions'];
        history.push(paths[newValue]);
    };

    return isAdmin ? (
        <>
            <AppBar position="sticky" color="default" elevation={1}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="admin navigation tabs"
                >
                    <StyledTab icon={<DescriptionIcon />} label={isNarrow ? '' : 'Orders'} />
                    <StyledTab icon={<EggIcon />} label={isNarrow ? '' : 'Pods'} />
                    <StyledTab icon={<InventoryIcon />} label={isNarrow ? '' : 'Products'} />
                    <StyledTab
                        icon={<CalendarTodayIcon />}
                        label={isNarrow ? '' : 'Subscriptions'}
                    />
                    <StyledTab icon={<AttachMoneyIcon />} label={isNarrow ? '' : 'Commissions'} />
                </Tabs>
            </AppBar>
            <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Switch>
                    <Route path="/admin/orders" component={OrdersList} />
                    <Route path="/admin/pods" component={PodList} />
                    <Route path="/admin/products" component={PhysicalProductList} />
                    <Route path="/admin/subscriptions" component={SubscriptionList} />
                    <Route path="/admin/commissions" component={CommissionList} />
                </Switch>
            </Container>
        </>
    ) : (
        <Redirect to="/" />
    );
}
