import { Button, Container, Dialog, Typography, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNetworkingBinaryTree, useNetworkingOrganization } from '../state/NetworkingContext';
import DashboardCard from './DashboardCard';
import { CopyLink } from '../../../components';
import DialogContent from '@mui/material/DialogContent';
import QRCode from 'react-qr-code';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const PartnershipDashboardScene = () => {
    const binaryTree = useNetworkingBinaryTree();
    const organization = useNetworkingOrganization();
    const [data, setData] = useState<{ title: string; value: number }[]>([]);
    const [openQR, setOpenQR] = React.useState(false);

    const handleClickOpenQR = () => {
        setOpenQR(true);
    };

    const handleCloseQR = () => {
        setOpenQR(false);
    };
    useEffect(() => {
        if (!binaryTree.data) {
            setData([]);
            return;
        }
        const d = [];
        d.push({ title: 'Current Rank', value: binaryTree.data.rank });
        d.push({ title: 'Current Leadership Rank', value: binaryTree.data.leadership_rank });
        d.push({ title: 'Marketplace Sales', value: binaryTree.data.levels.marketplaceSales });
        d.push({ title: 'Paid Athlete Count', value: binaryTree.data.levels.athleteCount });
        d.push({
            title: 'Organization Subscription Level',
            value: binaryTree.data.levels.organizationSubscriptionLevel,
        });
        setData(d);
    }, [binaryTree.data]);
    return (
        <Container
            sx={{
                flex: 1,
            }}
            maxWidth={'xl'}
        >
            <Box>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <Typography sx={{ mt: 10, mb: 10 }} textAlign="center" variant={'h6'}>
                                Share Your Organization Sign Up Link!
                            </Typography>
                            <Typography
                                sx={{ marginTop: 20, marginBottom: 20 }}
                                variant={'caption'}
                            >
                                Use this link or QR code so your Direct Sponsor are placed in your
                                1st Level Enroller Tree
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CopyLink
                            onCopyToClipboard={() => {
                                console.log('copied');
                            }}
                            link={`${window.location.host}/invite-organization/${organization.data?.uuid}`}
                        />
                    </Grid>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <Button onClick={handleClickOpenQR}>Click to Show QR Code</Button>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={openQR}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseQR}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <QRCode
                                size={356}
                                style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%',
                                    marginTop: 10,
                                }}
                                value={`${window.location.host}/invite-organization/${organization.data?.uuid}`}
                                viewBox={`0 0 256 256`}
                            />
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        display: 'grid',
                        gap: 4,
                        gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
                        mt: 2,
                    }}
                >
                    {data.map((item) => (
                        <DashboardCard key={item.title} title={item.title} value={item.value} />
                    ))}
                </Box>
            </Box>
        </Container>
    );
};

export default PartnershipDashboardScene;
