import {
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    Select,
    Grid,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Dialog,
    Box,
    Snackbar,
    Typography,
    Stack,
} from '@mui/material';
import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    userSubscriptionsIsLoadedSelector,
    userSubscriptionsSelector,
} from '../../../redux/reducers/user';
import { createAthleteCheckoutSession } from '../athletes.api';
import AthleteSignupCard from '../components/AthleteSignupCard';
import { getPhysicalProducts } from '../../../api/Auth/getPhysicalProducts';
import { OrganizationPublic, PhysicalProductForPurchase } from '../../../utils';
import Pod from '../../../images/pod.png';
import createCheckoutPodAthleteSubscriptionWithVestsAndOptionalChargers from '../../../api/Auth/createCheckoutPodAthleteSubcriptionWithVests';
import Vest from '../../../images/vest.png';
import VestTransparent from '../../../images/vestTransparent.png';
import Charger from '../../../images/charger1.png';
import GoldMedal from '../../../images/gold-medal.png';
import managePodAthleteSubscription from '../../../api/Auth/managePodAthleteSubscription';
import createCheckoutAthletePhysicalItem from '../../../api/Auth/createCheckoutAthletePhysicalItem';
import PhysicalItemPurchaseDialog from '../../../components/Dialogs/PhysicalItemPurchaseDialog';
import getOrganizationBySlug from '../../../api/Public/getOrganizationBySlug';

const AthleteMarketplace = (): ReactElement => {
    const subscriptions = useSelector(userSubscriptionsSelector);
    const subscriptionsLoaded = useSelector(userSubscriptionsIsLoadedSelector);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isSubscribedToFiyrPod, setIsSubscribedToFiyrPod] = useState(false);
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState<OrganizationPublic | null>(null);
    const [snackMessage, setSnackMessage] = useState<string>('');

    const [podsForPurchase, setPodsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [vestsForPurchase, setVestsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [chargersForPurchase, setChargersForPurchase] = useState<PhysicalProductForPurchase[]>(
        [],
    );

    // For managing default subscription
    const [isManagingDefaultSubscription, setIsManagingDefaultSubscription] =
        useState<boolean>(false);

    // For purchasing pods with vests
    const [isPurchasingPods, setIsPurchasingPods] = useState<boolean>(false);
    const [isManagingPods, setIsManagingPods] = useState<boolean>(false);
    const [selectedPodForPurchase, setSelectedPodForPurchase] = useState<string>('');
    const [selectedVestCountsForPods, setSelectedVestCountsForPods] = useState<
        Record<string, number>
    >({});
    const [selectedChargerCountsForPods, setSelectedChargerCountsForPods] = useState<
        Record<string, number>
    >({});

    // For purchasing vests alone
    const [isPurchasingVests, setIsPurchasingVests] = useState<boolean>(false);

    // For purchasing chargers alone
    const [isPurchasingChargers, setIsPurchasingChargers] = useState<boolean>(false);

    // Flat rate shipping fee $15
    const SHIPPING_FEE = 15;

    useEffect(() => {
        const getOrganizationFromUrlSlug = async () => {
            try {
                const response = await getOrganizationBySlug(
                    window.location.pathname.split('/')[2],
                );
                console.log(response.data);
                setOrganization(response.data);
            } catch (e) {
                window.location.href = '/athletes';
            }
        };

        getOrganizationFromUrlSlug();
    }, []);

    useEffect(() => {
        if (
            subscriptions.some(
                (subscription) =>
                    subscription.stripe_status === 'active' && subscription.name === 'default',
            )
        ) {
            setIsSubscribed(true);
        }
        if (
            subscriptions.some(
                (subscription) =>
                    subscription.stripe_status === 'active' &&
                    subscription.name === 'athlete_fiyrpod',
            )
        ) {
            setIsSubscribedToFiyrPod(true);
        }
    }, [subscriptions]);

    const handleDefaultSubscriptionClick = async () => {
        /**
         * An athlete with an existing default subscription can cancel their subscription or change their billing information
         */
        if (isSubscribed) {
            setIsManagingDefaultSubscription(true);
            return;
        }

        setLoading(true);

        try {
            const response = await createAthleteCheckoutSession(
                window.location.href,
                process.env.REACT_APP_STRIPE_ATHLETE_PRICE as string,
                organization?.uuid as string,
            );
            setLoading(false);
            window.location.href = response.data.url;
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const manageDefaultSubscription = async (flow: string) => {
        createAthleteCheckoutSession(
            window.location.href,
            process.env.REACT_APP_STRIPE_ATHLETE_PRICE as string,
            organization?.uuid as string,
            flow,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                console.error(`Error managing 1Tul subscription. ${e.response?.data?.message}`);
            });
    };

    const handlePodSubscriptionClick = () => {
        /**
         * An athlete with an existing subscription can cancel their subscription or change their billing information
         */
        if (isSubscribedToFiyrPod) {
            setIsManagingPods(true);
            return;
        }

        getPhysicalProducts('Fiyrpod Single Charger', organization?.uuid as string)
            .then((response) => {
                setChargersForPurchase(response.data.data);
                setIsPurchasingPods(true);
            })
            .catch((e: any) => {
                console.error(`Error retrieving FiyrPod chargers. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('FiyrPod Vest', organization?.uuid as string)
            .then((response) => {
                setVestsForPurchase(response.data.data);
                setIsPurchasingPods(true);
            })
            .catch((e: any) => {
                console.error(`Error retrieving FiyrPod vests. ${e.response?.data?.message}`);
            });

        getPhysicalProducts('Fiyrpod', organization?.uuid as string)
            .then((response) => {
                setPodsForPurchase(response.data.data);
                setIsPurchasingPods(true);
            })
            .catch((e: any) => {
                console.error(`Error retrieving FiyrPods. ${e.response?.data?.message}`);
            });
    };

    const handlePodPurchase = async () => {
        createCheckoutPodAthleteSubscriptionWithVestsAndOptionalChargers(
            window.location.href,
            organization?.uuid as string,
            selectedPodForPurchase,
            selectedVestCountsForPods,
            selectedChargerCountsForPods,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                console.error(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const managePodSubscription = async (flow: string) => {
        managePodAthleteSubscription(window.location.href, flow)
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                console.error(`Error managing FiyrPod subscription. ${e.response?.data?.message}`);
            });
    };

    const handleVestPurchase = async (selectedVestCounts: Record<string, number>) => {
        createCheckoutAthletePhysicalItem(
            window.location.href,
            organization?.uuid as string,
            selectedVestCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const handleChargerPurchase = async (selectedChargerCounts: Record<string, number>) => {
        createCheckoutAthletePhysicalItem(
            window.location.href,
            organization?.uuid as string,
            selectedChargerCounts,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const handleVestForPodCountChange = (priceId: string, count: number) => {
        setSelectedVestCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const handleChargerForPodCountChange = (priceId: string, count: number) => {
        setSelectedChargerCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const calculateTotalPodVestChargerPurchaseCost = () => {
        let initialPrice = 60; // Price of single pod allowed to be purchased for athlete.
        const totalVestQuantity = Object.values(selectedVestCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalChargerQuantity = Object.values(selectedChargerCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        return (totalVestQuantity + totalChargerQuantity) * 40 + initialPrice;
    };

    return (
        <main>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                    backgroundImage: `url(${organization?.banner_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent gray overlay
                    },
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        align="center"
                        color="white"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            position: 'relative',
                            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.8)',
                            fontSize: '2.5rem',
                        }}
                    >
                        {organization?.name} Athlete Marketplace
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="white"
                        paragraph
                        sx={{
                            fontWeight: 'bold',
                            position: 'relative',
                            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {organization?.about_us
                            ? organization.about_us
                            : 'Buy products and subscriptions here.'}
                    </Typography>
                </Container>
            </Box>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    paddingTop: 20,
                }}
                maxWidth={'xl'}
            >
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={!!snackMessage}
                    autoHideDuration={6000}
                    message={snackMessage}
                    onClose={() => setSnackMessage('')}
                />
                {subscriptionsLoaded && organization && (
                    <>
                        <Grid container sx={{ width: '100%', justifyContent: 'center' }}>
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography variant="h5" component="div">
                                        Athlete Products and Subscriptions
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <AthleteSignupCard
                                    body={
                                        'Athlete access to 🚀GPS/1080 data, Train and Learn modules, and unlimited' +
                                        ' 🤖Video AI (if your coach has a Gold Member 🥇 Account). Access to mobile' +
                                        ' 🔥FiyrApp.'
                                    }
                                    organization={organization}
                                    secondaryBody={
                                        isSubscribed
                                            ? 'You are currently subscribed to our service. Click below to manage your subscription.'
                                            : ''
                                    }
                                    isSubscribed={isSubscribed}
                                    subscribedText={'1Tul Subscription'}
                                    notSubscribedText={'Subscribe Now'}
                                    subscribedButtonText={'Manage Subscription'}
                                    notSubscribedButtonText={'Subscribe Now $10/month'}
                                    disabled={loading}
                                    onButtonClick={handleDefaultSubscriptionClick}
                                    image={GoldMedal}
                                />
                            </Grid>
                            {isSubscribed && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AthleteSignupCard
                                            body={
                                                'Buy a 🔥FiyrPod GPS device directly to your 1Tul account ' +
                                                ' and get a FREE FiyrPod vest!'
                                            }
                                            organization={organization}
                                            secondaryBody={
                                                isSubscribedToFiyrPod
                                                    ? 'You are currently subscribed to our service. Click below to manage your subscription.'
                                                    : ''
                                            }
                                            isSubscribed={isSubscribedToFiyrPod}
                                            subscribedText={'FiyrPod Subscription'}
                                            notSubscribedText={'FiyrPod Subscription'}
                                            subscribedButtonText={'Manage Subscription'}
                                            notSubscribedButtonText={'Subscribe Now $60/month'}
                                            disabled={loading}
                                            onButtonClick={handlePodSubscriptionClick}
                                            image={Pod}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AthleteSignupCard
                                            body={
                                                'Buy 🔥FiyrPod vests! Keep your FiyrPod safe and secure while you train!'
                                            }
                                            organization={organization}
                                            secondaryBody=""
                                            isSubscribed={false}
                                            subscribedText={'FiyrPod Vests'}
                                            notSubscribedText={'FiyrPod Vests'}
                                            subscribedButtonText={'Buy FiyrPod Vests'}
                                            notSubscribedButtonText={'Buy FiyrPod Vests'}
                                            disabled={loading}
                                            onButtonClick={() => {
                                                getPhysicalProducts(
                                                    'FiyrPod Vest',
                                                    organization?.uuid as string,
                                                )
                                                    .then((response) => {
                                                        setVestsForPurchase(response.data.data);
                                                        setIsPurchasingVests(true);
                                                    })
                                                    .catch((e: any) => {
                                                        setSnackMessage(
                                                            `Error retrieving FiyrPod vests. ${e.response?.data?.message}`,
                                                        );
                                                    });
                                            }}
                                            image={VestTransparent}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AthleteSignupCard
                                            body={
                                                'Buy 🔥FiyrPod chargers! They come with a handy phone stand. Keep your FiyrPod charged and ready to go.'
                                            }
                                            organization={organization}
                                            secondaryBody=""
                                            isSubscribed={false}
                                            subscribedText={'FiyrPod Chargers'}
                                            notSubscribedText={'FiyrPod Chargers'}
                                            subscribedButtonText={'Buy Fiyrpod Chargers'}
                                            notSubscribedButtonText={'Buy Fiyrpod Chargers'}
                                            disabled={loading}
                                            onButtonClick={() => {
                                                getPhysicalProducts(
                                                    'FiyrPod Single Charger',
                                                    organization?.uuid as string,
                                                )
                                                    .then((response) => {
                                                        setChargersForPurchase(response.data.data);
                                                        setIsPurchasingChargers(true);
                                                    })
                                                    .catch((e: any) => {
                                                        setSnackMessage(
                                                            `Error retrieving FiyrPod chargers. ${e.response?.data?.message}`,
                                                        );
                                                    });
                                            }}
                                            image={Charger}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Dialog open={isManagingDefaultSubscription} maxWidth="sm" fullWidth>
                            <DialogTitle>Manage 1Tul Subscription</DialogTitle>
                            <DialogContent dividers sx={{ overflowY: 'scroll' }}>
                                <p>
                                    Would you like to change your billing information or cancel your
                                    1Tul subscription?
                                </p>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => manageDefaultSubscription('subscription_update')}
                                    variant="contained"
                                >
                                    Change Billing
                                </Button>
                                <Button
                                    onClick={() => manageDefaultSubscription('subscription_cancel')}
                                    variant="contained"
                                >
                                    Cancel Subscription
                                </Button>
                                <Button onClick={() => setIsManagingDefaultSubscription(false)}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={isPurchasingPods} maxWidth="xs" fullWidth>
                            <DialogTitle>Subscribe to Fiyrpod</DialogTitle>
                            <DialogContent dividers sx={{ overflowY: 'scroll' }}>
                                <FormControl fullWidth margin="normal" size="small">
                                    <InputLabel id="pod-select-label">Select Pod</InputLabel>
                                    <Select
                                        labelId="pod-select-label"
                                        value={selectedPodForPurchase}
                                        onChange={(e) =>
                                            setSelectedPodForPurchase(e.target.value as string)
                                        }
                                        label="Select Pod"
                                        size="small"
                                    >
                                        {podsForPurchase.map((pod) =>
                                            pod.inventory_count > 0 ? (
                                                <MenuItem key={pod.uuid} value={pod.uuid}>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: 24,
                                                            width: 24,
                                                            marginRight: 2,
                                                            display: 'inline-block',
                                                            verticalAlign: 'middle',
                                                        }}
                                                        alt="Pod"
                                                        src={Pod}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            width: 'calc(80% - 24px)',
                                                            fontSize: '0.8rem',
                                                            display: 'inline-block',
                                                        }}
                                                    >
                                                        {pod.name}
                                                        <span style={{ fontWeight: '500' }}>
                                                            {' $'}
                                                            {(pod.price / 100).toFixed(2)}/month
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: 'green',
                                                                fontStyle: 'italic',
                                                                fontSize: '0.65rem',
                                                            }}
                                                        >
                                                            {' '}
                                                            {pod.inventory_count} available
                                                        </span>
                                                    </Typography>
                                                </MenuItem>
                                            ) : null,
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <p style={{ fontSize: '0.8rem', fontWeight: '500' }}>
                                        Vests (select at least one)
                                    </p>
                                </FormControl>
                                {vestsForPurchase.map((vest) => (
                                    <FormControl
                                        key={vest.uuid}
                                        fullWidth
                                        margin="normal"
                                        style={{ marginTop: 0, marginBottom: '8px' }}
                                    >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 24,
                                                    width: 24,
                                                    marginRight: 2,
                                                }}
                                                alt="Vest"
                                                src={Vest}
                                            />
                                            <Typography
                                                sx={{
                                                    width: 'calc(80% - 24px)',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                {' '}
                                                {vest.name}
                                                <span style={{ fontWeight: '500' }}> $0 </span>
                                                <span
                                                    style={{
                                                        color:
                                                            vest.inventory_count > 0
                                                                ? 'green'
                                                                : 'red',
                                                        fontStyle: 'italic',
                                                        fontSize: '0.65rem',
                                                    }}
                                                >
                                                    {vest.inventory_count > 0
                                                        ? ` ${vest.inventory_count} available`
                                                        : ' available soon'}
                                                </span>
                                            </Typography>
                                            {vest.inventory_count > 0 && (
                                                <Stack sx={{ width: '20%' }}>
                                                    <Select
                                                        value={
                                                            selectedVestCountsForPods[
                                                                vest.stripe_price_id
                                                            ] || 0
                                                        }
                                                        onChange={(e) =>
                                                            handleVestForPodCountChange(
                                                                vest.stripe_price_id,
                                                                Number(e.target.value),
                                                            )
                                                        }
                                                        fullWidth
                                                        size="small"
                                                        sx={{ fontSize: '0.8rem' }}
                                                    >
                                                        {[
                                                            ...Array(
                                                                vest.inventory_count + 1,
                                                            ).keys(),
                                                        ].map((count) => (
                                                            <MenuItem
                                                                key={count}
                                                                value={count}
                                                                sx={{ fontSize: '0.8rem' }}
                                                            >
                                                                {count}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </FormControl>
                                ))}
                                <FormControl>
                                    <p style={{ fontSize: '0.8rem', fontWeight: '500' }}>
                                        Chargers (optional)
                                    </p>
                                </FormControl>
                                {chargersForPurchase.map((charger) => (
                                    <FormControl
                                        key={charger.uuid}
                                        fullWidth
                                        margin="normal"
                                        style={{ marginTop: 0, marginBottom: '8px' }}
                                    >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 24,
                                                    width: 24,
                                                    marginRight: 2,
                                                }}
                                                alt="Charger"
                                                src={Charger}
                                            />
                                            <Typography
                                                sx={{
                                                    width: 'calc(80% - 24px)',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                {' '}
                                                {charger.name}
                                                <span style={{ fontWeight: '500' }}> $0 </span>
                                                <span
                                                    style={{
                                                        color:
                                                            charger.inventory_count > 0
                                                                ? 'green'
                                                                : 'red',
                                                        fontStyle: 'italic',
                                                        fontSize: '0.65rem',
                                                    }}
                                                >
                                                    {charger.inventory_count > 0
                                                        ? ` ${charger.inventory_count} available`
                                                        : ' available soon'}
                                                </span>
                                            </Typography>
                                            {charger.inventory_count > 0 && (
                                                <Stack sx={{ width: '20%' }}>
                                                    <Select
                                                        value={
                                                            selectedChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0
                                                        }
                                                        onChange={(e) =>
                                                            handleChargerForPodCountChange(
                                                                charger.stripe_price_id,
                                                                Number(e.target.value),
                                                            )
                                                        }
                                                        fullWidth
                                                        size="small"
                                                        sx={{ fontSize: '0.8rem' }}
                                                    >
                                                        {[
                                                            ...Array(
                                                                charger.inventory_count + 1,
                                                            ).keys(),
                                                        ].map((count) => (
                                                            <MenuItem
                                                                key={count}
                                                                value={count}
                                                                sx={{ fontSize: '0.8rem' }}
                                                            >
                                                                {count}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </FormControl>
                                ))}
                            </DialogContent>
                            {selectedPodForPurchase &&
                            Object.keys(selectedVestCountsForPods).length > 0 ? (
                                <div style={{ padding: '10px 16px 4px 0', textAlign: 'right' }}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            fontSize: '0.65rem',
                                            color: '#424242',
                                            fontWeight: '450',
                                        }}
                                    >
                                        Subtotal: ${calculateTotalPodVestChargerPurchaseCost()}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            fontSize: '0.6rem',
                                            color: '#424242',
                                        }}
                                    >
                                        Tax: (will be calculated)
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            fontSize: '0.6rem',
                                            color: '#424242',
                                        }}
                                    >
                                        Shipping: ${SHIPPING_FEE.toFixed(2)}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            fontSize: '0.65rem',
                                            color: '#424242',
                                            fontWeight: '600',
                                        }}
                                    >
                                        Total: $
                                        {(
                                            calculateTotalPodVestChargerPurchaseCost() +
                                            SHIPPING_FEE
                                        ).toFixed(2)}
                                    </Typography>
                                </div>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '4px',
                                    }}
                                >
                                    <span style={{ fontSize: '0.65rem', color: 'red' }}>
                                        Select one pod subscription and at least one vest
                                    </span>
                                </Box>
                            )}
                            <DialogActions>
                                <Button onClick={() => setIsPurchasingPods(false)}>Cancel</Button>
                                <Button
                                    onClick={handlePodPurchase}
                                    variant="contained"
                                    disabled={
                                        !selectedPodForPurchase ||
                                        Object.keys(selectedVestCountsForPods).length === 0
                                    }
                                >
                                    Purchase
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={isManagingPods} maxWidth="sm" fullWidth>
                            <DialogTitle>Manage Fiyrpod Subscription</DialogTitle>
                            <DialogContent dividers sx={{ overflowY: 'scroll' }}>
                                <p>
                                    Would you like to change your billing information or cancel your
                                    Fiyrpod subscription?
                                </p>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => managePodSubscription('subscription_update')}
                                    variant="contained"
                                >
                                    Change Billing
                                </Button>
                                <Button onClick={() => setIsManagingPods(false)}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        <PhysicalItemPurchaseDialog
                            open={isPurchasingVests}
                            onClose={() => setIsPurchasingVests(false)}
                            items={vestsForPurchase}
                            onPurchase={handleVestPurchase}
                            title="Purchase Vests"
                            imageSource={Vest}
                            shippingFee={SHIPPING_FEE}
                        />

                        <PhysicalItemPurchaseDialog
                            open={isPurchasingChargers}
                            onClose={() => setIsPurchasingChargers(false)}
                            items={chargersForPurchase}
                            onPurchase={handleChargerPurchase}
                            title="Purchase Chargers"
                            imageSource={Charger}
                            shippingFee={SHIPPING_FEE}
                        />
                    </>
                )}
                {!subscriptionsLoaded && <CircularProgress />}
            </Container>
        </main>
    );
};

export default AthleteMarketplace;
