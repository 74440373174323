import React, { useContext } from 'react';
import { useToggle } from 'utils';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Chip,
    CircularProgress,
    Fab,
    Grid,
    Hidden,
    IconButton,
    Input,
    Typography,
} from '@mui/material';
import { Star, StarOutline } from '@mui/icons-material';
import { OrganizationFilterList } from '../Filters';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { CustomButton } from 'components/Buttons';
import { SnackBar } from 'components/Indicators';
import { useTheme } from '@mui/system';

type ListFilters = { isFavorite?: boolean; 'filter[organization]'?: string };

interface ListWithSearchContainerProps {
    isLoading?: boolean;
    buttonText: string;
    onClickButton: React.MouseEventHandler;
    children: React.ReactNode;
    searchBarPlaceHolder: string;
    searchValue: string;
    showChips?: boolean;
    setSearchValue: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    setOpen: (newVal: boolean) => void;
    isError: boolean;
    errorMessage: string;
    searchBarLoader?: boolean;
    onFilter?: (filters: ListFilters) => void;
    enabledFilters?: ListFilters;
    hasFavorite?: boolean;
}

const ListWithSearchContainer = ({
    buttonText,
    onClickButton,
    children,
    showChips = false,
    searchBarPlaceHolder,
    searchValue,
    setSearchValue,
    isError,
    setOpen,
    errorMessage,
    searchBarLoader = false,
    onFilter,
    enabledFilters,
    hasFavorite = false,
}: ListWithSearchContainerProps) => {
    const theme = useTheme();
    const [newFilter, toggleNewFilter] = useToggle(false);
    const [defaultFilter, toggleDefaultFilter] = useToggle(false);
    const { organizations } = useContext(OrganizationsContext);

    const onOrganizationSelected = (organization: string | false) => {
        if (organization) {
            onFilter && onFilter({ 'filter[organization]': organization });
        }
    };

    return (
        <Box
            sx={{
                flex: 1,
                padding: theme.spacing(5),
                color: theme.palette.text.secondary,
                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(4),
                },
                overflow: 'auto',
            }}
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Hidden mdDown>
                    <Grid item>
                        <CustomButton
                            onClick={onClickButton}
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{
                                fontSize: '15px',
                                borderRadius: '24px',
                                minWidth: '300px',
                                padding: theme.spacing(5),
                                boxShadow:
                                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                            }}
                            startIcon={<AddIcon />}
                        >
                            {buttonText}
                        </CustomButton>
                    </Grid>
                    {showChips && (
                        <Box sx={{ display: 'flex' }}>
                            <Chip
                                sx={{
                                    margin: (theme) => theme.spacing(0, 1),
                                    color: newFilter
                                        ? theme.palette.background.paper
                                        : theme.palette.text.secondary,
                                }}
                                color={newFilter ? 'primary' : 'default'}
                                variant={newFilter ? 'filled' : 'outlined'}
                                label="New"
                                clickable
                                onClick={toggleNewFilter}
                            />
                            <Chip
                                sx={{
                                    margin: (theme) => theme.spacing(0, 1),
                                    color: defaultFilter
                                        ? theme.palette.background.paper
                                        : theme.palette.text.secondary,
                                }}
                                color={defaultFilter ? 'primary' : 'default'}
                                variant={defaultFilter ? 'filled' : 'outlined'}
                                label="Drafts"
                                clickable
                                onClick={toggleDefaultFilter}
                            />
                        </Box>
                    )}
                </Hidden>

                <Grid item sx={{ padding: 3, marginBottom: 7 }}>
                    <OrganizationFilterList
                        organizations={organizations}
                        onClicked={(organization) => onOrganizationSelected(organization.uuid)}
                        selectedOrganization={enabledFilters?.['filter[organization]'] ?? ''}
                    />
                </Grid>
                <Grid item>
                    <Input
                        onChange={(e) => {
                            e.stopPropagation();
                            setSearchValue(e);
                        }}
                        defaultValue={searchValue}
                        sx={{
                            minWidth: 300,
                            borderRadius: '32px',
                            padding: theme.spacing(5),
                            margin: theme.spacing(2, 0, 2, 2),
                            background: theme.palette.background.paper,
                            boxShadow:
                                '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                            color: theme.palette.text.secondary,
                            '&::before': {
                                borderBottom: 'none',
                            },
                            '&:after': {
                                borderBottom: 'none',
                            },
                            '& .MuiInputBase-input': {
                                marginLeft: theme.spacing(6),
                            },
                            '&::placeholder': {
                                color: theme.palette.primary.main,
                                marginLeft: theme.spacing(6),
                            },
                            [theme.breakpoints.down(900)]: {
                                minWidth: 250,
                            },
                            [theme.breakpoints.down('md')]: {
                                borderRadius: '32px',
                                margin: theme.spacing(5),
                            },
                        }}
                        startAdornment={<SearchIcon />}
                        placeholder={searchBarPlaceHolder}
                        endAdornment={searchBarLoader && <CircularProgress size={30} />}
                    />
                </Grid>
            </Grid>
            {hasFavorite && (
                <Box
                    sx={{
                        marginBottom: 6,
                        marginTop: 5,
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            onFilter && onFilter({ isFavorite: !enabledFilters?.isFavorite });
                        }}
                        size="large"
                    >
                        {enabledFilters?.isFavorite ? (
                            <Star sx={{ color: theme.palette.secondary.main }} />
                        ) : (
                            <StarOutline />
                        )}{' '}
                        <Typography variant="body2">Favorites</Typography>
                    </IconButton>
                </Box>
            )}
            <Hidden mdUp>
                <Fab
                    sx={{
                        position: 'fixed',
                        zIndex: 1200,
                        bottom: theme.spacing(18),
                        left: '61%',
                        transform: 'translateX(-50%)',
                        '&:hover': {
                            backgroundColor: theme.palette.backgroundColor.freeSpeechBlue,
                        },
                    }}
                    color="inherit"
                    onClick={onClickButton}
                >
                    <AddIcon />
                </Fab>
            </Hidden>
            <Box
                sx={{
                    width: '100%',

                    [theme.breakpoints.down('lg')]: {
                        padding: theme.spacing(0, 0),
                    },
                }}
            >
                {children}
            </Box>
            <SnackBar open={isError} message={errorMessage} severity="error" setOpen={setOpen} />
        </Box>
    );
};

export default ListWithSearchContainer;
