import { AxiosResponse } from 'axios';
import { MarketplaceItem, SimplePaginateResponse } from '../app.types';
import { Request } from './index';

export const getAllItems = (
    organizationUuid: string,
    query: Record<string, unknown> = {},
): Promise<AxiosResponse<SimplePaginateResponse<MarketplaceItem>>> => {
    return Request.get(`/organizations/${organizationUuid}/marketplace/items`, {
        params: query,
    });
};

export const updateItem = (
    organizationUuid: string,
    itemUuid: string,
    values: Record<'price', string>,
): Promise<AxiosResponse<MarketplaceItem>> => {
    return Request.put(`/organizations/${organizationUuid}/marketplace/items/${itemUuid}`, values);
};

export const createItem = (
    organizationUuid: string,
    uuid: string,
    type: 'session' | 'form',
    price: number,
): Promise<AxiosResponse<MarketplaceItem>> => {
    return Request.post(`/organizations/${organizationUuid}/marketplace/items`, {
        uuid,
        type,
        price,
    });
};

export const deleteItem = (
    organizationUuid: string,
    itemUuid: string,
): Promise<AxiosResponse<void>> => {
    return Request.delete(`/organizations/${organizationUuid}/marketplace/items/${itemUuid}`);
};
