import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GetOrdersParams, getOrders, getOrderStatuses } from '../orders.api';
import { Order } from '../orders.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import OrderDialog from '../components/OrderDialog';
import { Organization, UserData } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import { debounce } from 'lodash';
import { adminGetAllUsers } from '../../../admin.users.api';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(20% - 16px)', // Subtracting margin to maintain layout
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        // Changed from 'sm' to 'md'
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1), // Negative margin to counteract item margins
    [theme.breakpoints.down('md')]: {
        // Changed from 'sm' to 'md'
        flexDirection: 'column',
    },
}));

const OrdersList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [sellerOrganizations, setSellerOrganizations] = useState<Organization[]>([]);
    const [buyerOrganizations, setBuyerOrganizations] = useState<Organization[]>([]);
    const [buyerAthletes, setBuyerAthletes] = useState<UserData[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [searchParams, setSearchParams] = useState<GetOrdersParams>({
        order_status: 'processing',
        seller_org_uuid: '',
        buyer_org_uuid: '',
        athlete_uuid: '',
        sort: '-created_at',
        page: 1,
        per_page: 10,
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [orderStatuses, setOrderStatuses] = useState<string[]>([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [receivedOrdersCount, setReceivedOrdersCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [hasDialogBeenShown, setHasDialogBeenShown] = useState(false);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1, // API usually expects 1-indexed pages
            per_page: newModel.pageSize,
        });
        getOrders({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        })
            .then((response) => {
                setOrders(response.data.data);
                setTotalOrders(response.data.meta.total);
                setReceivedOrdersCount(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch orders', error);
            });
    };

    const handleOpenDialog = (order: Order) => {
        setSelectedOrder(order);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleSaveOrder = async () => {
        handleCloseDialog();
        await getOrderSearchResults(searchParams);
    };

    interface LineItem {
        description: string;
        quantity: number;
    }

    function formatLineItemsDescription(lineItems: LineItem[]): string {
        const maxDescriptionLength = 50; // Adjust as needed
        let description = lineItems
            .map((item) => `${item.description} [${item.quantity}]`)
            .join(', ');

        // Truncate and append ellipsis if overflows
        if (description.length > maxDescriptionLength) {
            description = `${description.substring(0, maxDescriptionLength - 3)}...`;
        }

        return description;
    }

    const fetchBuyerOrganizations = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setBuyerOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    const fetchBuyerAthletes = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllUsers(searchTerm, perPage)
            .then((response) => {
                setBuyerAthletes(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch athletes', error);
            });
    }, 300);

    const fetchSellerOrganizations = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setSellerOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchBuyerOrganizations('', 500);
        fetchBuyerAthletes('', 500);
        fetchSellerOrganizations('', 500);
        const fetchOrderStatuses = async () => {
            const response = await getOrderStatuses();
            setOrderStatuses(response.data);
        };
        fetchOrderStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOrders({ order_status: 'processing', sort: '-created_at' })
            .then((response) => {
                setOrders(response.data.data);
                setTotalOrders(response.data.meta.total);
                setReceivedOrdersCount(response.data.meta.total);

                if (response.data.meta.total > 0 && !hasDialogBeenShown) {
                    setDialogOpen(true);
                    setHasDialogBeenShown(true);
                }
            })
            .catch((error) => {
                console.error('Failed to fetch orders', error);
            });
    }, [hasDialogBeenShown]);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    const columns: GridColDef<Order, any, any>[] = [
        {
            field: 'seller_org_name',
            headerName: 'Seller',
            width: 200,
            sortable: false,
            type: 'string',
        },
        {
            field: 'buyerName',
            headerName: 'Buyer',
            width: 200,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return (
                    params.row.buyer_org_name ??
                    `${params.row.buyer_athlete?.first_name} ${params.row.buyer_athlete?.last_name}`
                );
            },
        },
        {
            field: 'salesDescription',
            headerName: 'Order',
            width: 300,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                // Assuming each order has a lineItems property
                const description = formatLineItemsDescription(params.row.line_items);
                return <span>{description}</span>;
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            sortable: false,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'tax',
            headerName: 'Tax',
            width: 100,
            sortable: true,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'shipping',
            headerName: 'Shipping',
            width: 100,
            sortable: false,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'shipping_tracking_number',
            headerName: 'Tracking Number',
            width: 250,
            sortable: false,
            type: 'string',
        },
        {
            field: 'order_status',
            headerName: 'Order Status',
            width: 150,
            sortable: true,
            type: 'string',
        },
        {
            field: 'created_at',
            headerName: 'Received',
            sortable: true,
            width: 150,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            renderCell: (params) => (
                <Button onClick={() => handleOpenDialog(params.row)} variant="contained">
                    EDIT
                </Button>
            ),
        },
    ];

    const handleOrderSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await getOrderSearchResults(searchParams);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrderSearchResults = async (params: any) => {
        const response = await getOrders(params);
        if (Array.isArray(response.data.data)) {
            const updatedOrders = response.data.data.map((order: Order) => {
                return {
                    ...order,
                };
            });
            setOrders(updatedOrders);
            setTotalOrders(response.data.meta.total);
        } else {
            console.error('Unexpected data format: pods data is not an array');
        }
    };

    const handleOrderSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetOrdersParams['sort'],
        };

        await getOrderSearchResults(params);
    };

    // @ts-ignore
    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <h2>Marketplace Product and Subscription Orders</h2>
            <section style={{ marginBottom: theme.spacing(2) }}>
                <h4>Search Orders</h4>
                <ResponsiveForm onSubmit={handleOrderSearch} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                sellerOrganizations.find(
                                    (org) => org.uuid === searchParams.seller_org_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setSearchParams({
                                    ...searchParams,
                                    seller_org_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchSellerOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={sellerOrganizations}
                            getOptionLabel={(option) => `${option.name} - ${option.uuid}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Seller Organizations" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                buyerOrganizations.find(
                                    (org) => org.uuid === searchParams.buyer_org_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setSearchParams({
                                    ...searchParams,
                                    buyer_org_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchBuyerOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={buyerOrganizations}
                            getOptionLabel={(option) => `${option.name} - ${option.uuid}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Buyer Organizations" />
                            )}
                            disabled={searchParams.athlete_uuid !== ''}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                buyerAthletes.find(
                                    (athlete) => athlete.uuid === searchParams.athlete_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setSearchParams({
                                    ...searchParams,
                                    athlete_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchBuyerAthletes(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={buyerAthletes}
                            getOptionLabel={(option) =>
                                `${option.first_name} ${option.last_name} (${option.user_name})`
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Buyer Athletes" />
                            )}
                            disabled={searchParams.buyer_org_uuid !== ''}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="order-status-label">Order Status</InputLabel>
                            <Select
                                label="Order Status"
                                value={
                                    searchParams.order_status === ''
                                        ? 'all'
                                        : searchParams.order_status
                                }
                                onChange={(e) => {
                                    setSearchParams({
                                        ...searchParams,
                                        order_status:
                                            e.target.value === 'all' ? '' : e.target.value,
                                    });
                                }}
                                size="small"
                            >
                                <MenuItem value="all">all</MenuItem>
                                {orderStatuses.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Orders
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>
            <Box width="98%" maxHeight="600px" overflow="auto">
                <DataGridPremium
                    rows={orders}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model: GridSortModel) => handleOrderSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    pagination
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    rowCount={totalOrders}
                    paginationMode="server"
                    disableColumnMenu={true}
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}
                />
            </Box>
            <OrderDialog
                open={isDialogOpen}
                order={selectedOrder}
                onClose={handleCloseDialog}
                onSave={handleSaveOrder}
            />
            <Box style={{ marginLeft: '2rem' }}>
                <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby="received-orders-dialog-title"
                    aria-describedby="received-orders-dialog-description"
                >
                    <DialogTitle id="received-orders-dialog-title">Orders</DialogTitle>
                    <DialogContent>
                        <p id="received-orders-dialog-description">
                            You have {receivedOrdersCount} orders to fulfill.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setDialogOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default OrdersList;
