import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { CreatePodParams, Pod, UpdatePodParams } from './pods.types';

interface PaginatedApiResponse {
    data: Array<Pod>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

interface GetPodsParams {
    uuid?: string;
    serial_number?: string;
    club_id?: string;
    simple_id?: string;
    organization_uuid?: string;
    athlete_user_uuid?: string;
    created_at?: string;
    sort?: 'serial_number' | 'created_at' | '-serial_number' | '-created_at';
    between_dates?: Array<Date | undefined>;
    page?: number;
    per_page?: number;
}

const getPods = (params?: GetPodsParams): ReturnTypeCollection => {
    const filterParams = {
        'filter[organization_uuid]': params?.organization_uuid,
        'filter[serial_number]': params?.serial_number,
        'filter[simple_id]': params?.simple_id,
        'filter[athlete_user_uuid]': params?.athlete_user_uuid,
        sort: params?.sort,
        //between_dates: params?.between_dates,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/pods`, { params: filterParams });
};

type ReturnTypeSingle = Promise<AxiosResponse<Pod>>;

const createPod = (podData: CreatePodParams): ReturnTypeSingle => {
    return Request.post(`admin/pods`, podData);
};

const updatePod = (podUuid: string, podData: UpdatePodParams): ReturnTypeSingle => {
    return Request.put(`admin/pods/${podUuid}`, podData);
};

const changePodOrganization = (podUuid: string, organizationUuid: string): ReturnTypeSingle => {
    return Request.post(`admin/pods/${podUuid}/change-organization`, {
        organization_uuid: organizationUuid,
    });
};

const changePodIndividual = (podUuid: string, individualUuid: string): ReturnTypeSingle => {
    return Request.post(`admin/pods/${podUuid}/change-individual`, {
        individual_uuid: individualUuid,
    });
};

const detachPodIndividual = (podUuid: string): ReturnTypeSingle => {
    return Request.post(`admin/pods/${podUuid}/detach-individual`);
};

export {
    createPod,
    getPods,
    updatePod,
    changePodOrganization,
    changePodIndividual,
    detachPodIndividual,
};
export type { GetPodsParams };
