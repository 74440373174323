import Request from 'api/Request';
import { AxiosResponse } from 'axios';
import { PhysicalProductForPurchase, Meta, PaginationLink } from '../../utils';

type PhysicalProductsForPurchaseReturnType = {
    data: PhysicalProductForPurchase[];
    links: PaginationLink;
    meta: Meta;
};

type ReturnTypeProduct = Promise<AxiosResponse<PhysicalProductsForPurchaseReturnType>>;

const getPhysicalProducts = (
    product_category: string,
    organization_uuid: string,
): ReturnTypeProduct => {
    return Request.get(`/organizations/${organization_uuid}/physical-product-list`, {
        params: {
            'filter[product_category]': product_category,
            'filter[is_active]': true,
            sort: '-inventory_count',
        },
    });
};

export { getPhysicalProducts };
