import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { CheckCircle, CheckCircleOutline, Description, LibraryAdd } from '@mui/icons-material';
import MovementSelectionDialog from 'components/Dialogs/MovementSelectionDialog';
import MovementMedia from 'components/Media/MovementMedia';
import { isEmpty, last } from 'lodash';
import { GoalChipSelect } from 'modules/train/components';
import React, { useState } from 'react';
import {
    AccelerationUnit,
    DistanceUnit,
    getHtml,
    LoadUnit,
    Log,
    Movement,
    PowerUnit,
    SetAttributeType,
    SpeedUnit,
    TimeDisplayFormat,
} from 'utils';
import { ActivityLogSet } from './EditLogForm';
import LogMovementRow from './LogMovementRow';
import SessionMovementGoalSettings from './SessionMovementGoalSettings';
import ButtonSaveLoader from '../Buttons/ButtonSaveLoader';
import SessionMovementGoalsTableHeader from './SessionMovementGoalsTableHeader';

export interface LogMovementsProps {
    activityLogSets: Array<ActivityLogSet>;
    onActivityLogAdded: (previousLog: Log) => void;
    onActivityLogRemoved: (removedLog: Log) => void;
    onActivityLogsRemoved: (removedLogs: Array<Log>) => void;
    onActivityLogStatusChanged: (updatedLog: Log) => void;
    onActivityLogValueChanged: (
        updatedLog: Log,
        updatedGoalCriteria: SetAttributeType,
        newValue: any,
    ) => void;
    onMultipleMovementsAdded: (movements: Array<Movement>) => void;
    onCriteriaAddedToActivityLogs: (updatedLogs: Array<Log>, criteria: SetAttributeType) => void;
    onCriteriaRemovedFromActivityLog: (updatedLogs: Array<Log>, criteria: SetAttributeType) => void;
    onActivityLogSetMarkedAsComplete: (updatedLogs: Array<Log>) => void;
    onActivityLogUnitChanged: (
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        oldUnit: string,
        newUnit: string,
    ) => void;
    organizationUuid?: string | undefined;
    onFinishClick: () => void;
    finishing: boolean;
}

const LogMovements = ({
    finishing,
    onFinishClick,
    activityLogSets,
    onActivityLogAdded,
    onActivityLogRemoved,
    onActivityLogsRemoved,
    onMultipleMovementsAdded,
    onActivityLogStatusChanged,
    onActivityLogValueChanged,
    onCriteriaRemovedFromActivityLog,
    onCriteriaAddedToActivityLogs,
    onActivityLogUnitChanged,
    onActivityLogSetMarkedAsComplete,
}: LogMovementsProps) => {
    const theme = useTheme();
    const [showMovementSelectionDialog, setShowMovementSelectionDialog] = useState<boolean>(false);
    const [openMovementAssetSliderIndex, setOpenMovementAssetSliderIndex] = useState<number | null>(
        null,
    );
    const [openMovementDescriptionSliderIndex, setOpenMovementDescriptionSliderIndex] = useState<
        number | null
    >(null);
    const breakPoint = useMediaQuery(theme.breakpoints.down('xl'));

    //A log set is a set of logs attached to the activity with reps, load, distance, time etc
    // A log can just have reps and load while other movement from same session might have
    // distance and time
    // This function will get the log criterias which are applicable for an activity set
    // This is used to show header columns as if there is no distance log for any set of a session movement
    // We don't need to show it in the header
    const getLogCriteriasFromActivityLogSet = (
        activityLogSet: ActivityLogSet,
    ): Array<SetAttributeType> => {
        let logCriterias: Set<SetAttributeType> = new Set();

        activityLogSet.logs.forEach((log) => {
            if (log?.reps != null) {
                logCriterias.add(SetAttributeType.Reps);
            }
            if (log?.load_value != null) {
                logCriterias.add(SetAttributeType.Load);
            }
            if (log?.time_value != null) {
                logCriterias.add(SetAttributeType.Time);
            }
            if (log?.distance_value != null) {
                logCriterias.add(SetAttributeType.Distance);
            }
            if (log?.range_of_motion_value != null) {
                logCriterias.add(SetAttributeType.RangeOfMotion);
            }
            if (log?.speed_value != null) {
                logCriterias.add(SetAttributeType.Speed);
            }
            if (log?.acceleration_value != null) {
                logCriterias.add(SetAttributeType.Acceleration);
            }
            if (log?.power_value != null) {
                logCriterias.add(SetAttributeType.Power);
            }
            if (log?.rsi_value != null) {
                logCriterias.add(SetAttributeType.RSI);
            }
            if (log?.force_value != null) {
                logCriterias.add(SetAttributeType.Force);
            }
            if (log?.body_side != null) {
                logCriterias.add(SetAttributeType.BodySide);
            }
            if (log?.rpe_value != null) {
                logCriterias.add(SetAttributeType.RPE);
            }
            if (log?.band_value != null) {
                logCriterias.add(SetAttributeType.Band);
            }
            if (log?.rest_value != null) {
                logCriterias.add(SetAttributeType.Rest);
            }
            if (log?.rpm_value != null) {
                logCriterias.add(SetAttributeType.RPM);
            }
            if (log?.gps_player_load_value != null) {
                logCriterias.add(SetAttributeType.GPSPlayerLoad);
            }
            if (log?.gps_accel_count_value != null) {
                logCriterias.add(SetAttributeType.GPSAccelCount);
            }
            if (log?.gps_decel_count_value != null) {
                logCriterias.add(SetAttributeType.GPSDecelCount);
            }
        });
        if (logCriterias.size == 0) {
            // Add reps if all movement presets are empty
            logCriterias.add(SetAttributeType.Reps);
        }

        return Array.from(logCriterias);
    };

    return (
        <>
            {activityLogSets.length > 0 ? (
                <>
                    {activityLogSets.map((activityLogSet, activityLogSetIndex) => {
                        const applicableCriterias =
                            getLogCriteriasFromActivityLogSet(activityLogSet);
                        return (
                            <Accordion
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderBottom: `1px solid transparent`,
                                        '&:hover, &:focus': {
                                            borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                        },
                                    },
                                }}
                                key={`activity-movement-set-${activityLogSetIndex}`}
                                expanded={true}
                            >
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ width: '100%', gap: theme.spacing(5) }}
                                        flexWrap="wrap"
                                    >
                                        <Box
                                            textAlign="center"
                                            onClick={() => {
                                                if (
                                                    openMovementAssetSliderIndex ==
                                                    activityLogSetIndex
                                                ) {
                                                    setOpenMovementAssetSliderIndex(null);
                                                } else {
                                                    setOpenMovementAssetSliderIndex(
                                                        activityLogSetIndex,
                                                    );
                                                    setOpenMovementDescriptionSliderIndex(null);
                                                }
                                            }}
                                            width={
                                                openMovementAssetSliderIndex == activityLogSetIndex
                                                    ? '100%'
                                                    : 80
                                            }
                                            flex={
                                                openMovementAssetSliderIndex == activityLogSetIndex
                                                    ? '0 0 100%'
                                                    : 'inherit'
                                            }
                                        >
                                            <MovementMedia movement={activityLogSet.movement} />
                                        </Box>
                                        {!isEmpty(activityLogSet.movement?.description?.trim()) && (
                                            <IconButton
                                                onClick={() => {
                                                    if (
                                                        openMovementDescriptionSliderIndex ==
                                                        activityLogSetIndex
                                                    ) {
                                                        setOpenMovementDescriptionSliderIndex(null);
                                                    } else {
                                                        setOpenMovementDescriptionSliderIndex(
                                                            activityLogSetIndex,
                                                        );
                                                        setOpenMovementAssetSliderIndex(null);
                                                    }
                                                }}
                                                size="large"
                                            >
                                                <Description />
                                            </IconButton>
                                        )}

                                        <Typography component="div" sx={{ flexGrow: 1 }}>
                                            {activityLogSet.movement.name}
                                            {activityLogSet.group_title
                                                ? ` [${activityLogSet.group_title}]`
                                                : ''}
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                onActivityLogSetMarkedAsComplete(
                                                    activityLogSet.logs,
                                                )
                                            }
                                        >
                                            {activityLogSet.all_marked_as_complete ? (
                                                <CheckCircle color="secondary" />
                                            ) : (
                                                <CheckCircleOutline />
                                            )}
                                        </Button>

                                        {/* Settings */}
                                        <SessionMovementGoalSettings
                                            removeConfirmationMessage={`This will remove ${activityLogSet.logs.length} sets of ${activityLogSet.movement.name}.`}
                                            onRemoveConfirmed={() => {
                                                onActivityLogsRemoved(activityLogSet.logs);
                                            }}
                                            onUnitChanged={(
                                                criteria: SetAttributeType,
                                                oldUnit: string,
                                                newUnit: string,
                                            ) => {
                                                onActivityLogUnitChanged(
                                                    activityLogSet.logs,
                                                    criteria,
                                                    oldUnit,
                                                    newUnit,
                                                );
                                            }}
                                            distanceUnit={
                                                (activityLogSet.logs[0]?.distance_unit ||
                                                    DistanceUnit.Mile) as DistanceUnit
                                            }
                                            loadUnit={
                                                (activityLogSet.logs[0]?.load_unit ||
                                                    LoadUnit.Pound) as LoadUnit
                                            }
                                            timeDisplayFormat={
                                                (activityLogSet.logs[0]?.time_display_format ||
                                                    TimeDisplayFormat.Common) as TimeDisplayFormat
                                            }
                                            speedUnit={
                                                (activityLogSet.logs[0]?.speed_unit ||
                                                    SpeedUnit.MPH) as SpeedUnit
                                            }
                                            disableRemove={
                                                activityLogSet.logs.filter(
                                                    (log) => log.is_from_original_session,
                                                ).length > 0
                                            }
                                        />
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails
                                    sx={{
                                        display: 'block',
                                        backgroundColor: theme.palette.backgroundColor.hover,
                                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                                    }}
                                >
                                    {openMovementDescriptionSliderIndex == activityLogSetIndex && (
                                        <Box m={5}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: getHtml(
                                                        activityLogSet.movement.description || '',
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    )}

                                    <SessionMovementGoalsTableHeader
                                        applicableCriterias={applicableCriterias}
                                        loadUnit={
                                            (activityLogSet.logs[0]?.load_unit as LoadUnit) ||
                                            LoadUnit.Pound
                                        }
                                        distanceUnit={
                                            (activityLogSet.logs[0]
                                                ?.distance_unit as DistanceUnit) ||
                                            DistanceUnit.Mile
                                        }
                                        speedUnit={
                                            (activityLogSet.logs[0]?.speed_unit as SpeedUnit) ||
                                            SpeedUnit.MPH
                                        }
                                        accelerationUnit={
                                            (activityLogSet.logs[0]
                                                ?.acceleration_unit as AccelerationUnit) ||
                                            AccelerationUnit.FPS
                                        }
                                        powerUnit={
                                            (activityLogSet.logs[0]?.power_unit as PowerUnit) ||
                                            PowerUnit.Watt
                                        }
                                        timeDisplayFormat={
                                            (activityLogSet.logs[0]
                                                ?.time_display_format as TimeDisplayFormat) ||
                                            TimeDisplayFormat.Common
                                        }
                                    />

                                    {activityLogSet.logs.map((log, index) => (
                                        <LogMovementRow
                                            key={`goal-set-${activityLogSetIndex}-session-moment-row-${index}`}
                                            index={index}
                                            log={log}
                                            movementName={activityLogSet.movement.name}
                                            applicableCriterias={applicableCriterias}
                                            onActivityLogRemoved={onActivityLogRemoved}
                                            onActivityLogValueChanged={onActivityLogValueChanged}
                                            onActivityLogStatusChanged={onActivityLogStatusChanged}
                                        />
                                    ))}
                                    <Box
                                        textAlign="center"
                                        my={5}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ gap: 20 }}
                                        flexWrap="wrap"
                                    >
                                        <Button
                                            variant="text"
                                            size="small"
                                            sx={{ color: theme.palette.text.secondary }}
                                            onClick={() =>
                                                onActivityLogAdded(last(activityLogSet.logs) as Log)
                                            }
                                        >
                                            <LibraryAdd />
                                            &nbsp;Add Set
                                        </Button>

                                        {/* Chip selection */}
                                        <GoalChipSelect
                                            selectedGoals={applicableCriterias}
                                            onChipDeleted={(chip) =>
                                                onCriteriaRemovedFromActivityLog(
                                                    activityLogSet.logs,
                                                    chip,
                                                )
                                            }
                                            onChipSelected={(chip) =>
                                                onCriteriaAddedToActivityLogs(
                                                    activityLogSet.logs,
                                                    chip,
                                                )
                                            }
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </>
            ) : (
                <Box pt={20} display="flex" justifyContent="center" pb={20}>
                    <Typography variant="body2">No movements added.</Typography>
                </Box>
            )}
            {/* Actions of the log set */}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ gap: 10, padding: 10 }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowMovementSelectionDialog(true)}
                    size={breakPoint ? 'small' : undefined}
                >
                    Add Movement(s)
                </Button>

                {breakPoint && (
                    <>
                        <ButtonSaveLoader
                            variant="contained"
                            color="primary"
                            onClick={onFinishClick}
                            size="small"
                            disabled={finishing}
                        >
                            {finishing ? 'saving' : 'Finish'}
                        </ButtonSaveLoader>
                    </>
                )}
                <MovementSelectionDialog
                    open={showMovementSelectionDialog}
                    multiple
                    onCancel={() => setShowMovementSelectionDialog(false)}
                    onMultipleMovementsSelected={(movements: Array<Movement>) => {
                        onMultipleMovementsAdded(movements);
                    }}
                />
            </Box>
        </>
    );
};

export default LogMovements;
