import NavigationLayout from '../../Layouts/NavigationLayout';
import AthleteMarketplace from './scenes/AthleteMarketplace';
import AthleteHome from './scenes/AthleteHome';

export const athleteRoutes = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/athletes',
        private: true,
        component: AthleteHome,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/athletes/:organizationSlug',
        private: true,
        component: AthleteMarketplace,
    },
];
