// AdminContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import checkAdminStatus from '../api/Auth/checkAdminStatus';

const AdminContext = createContext<boolean>(false);

interface AdminProviderProps {
    children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const fetchAdminStatus = async () => {
            try {
                if (window.location.href.includes('/unauthorized')) {
                    return;
                }

                const response = await checkAdminStatus();
                setIsAdmin(response.status === 200);
            } catch (error) {
                setIsAdmin(false);
            }
        };

        fetchAdminStatus();
    }, []);

    return <AdminContext.Provider value={isAdmin}>{children}</AdminContext.Provider>;
};

export const UseAdminStatus = () => useContext(AdminContext);
