import { AlertColor } from '@mui/material/Alert/Alert';
import Evaporate from 'evaporate';
import { DateTime } from 'luxon';
import { EncodingOptionsPayload, Tag } from '../app.types';
import { TimezoneKey } from './timezones';

declare global {
    interface Window {
        qPlayer: any;
        qencodePlayers: any;
    }
}

export enum AssetVersionType {
    Uploaded = 'uploaded',
    Original = 'original',
    Thumbnail = 'thumbnail',
    Hls = 'hls',
    Gif = 'gif',
    Mp4 = 'mp4',
    Mp4Clip = 'mp4_clip',
    WebmClip = 'webm_clip',
}

export type OrganizationActions =
    | 'organization:update'
    | 'organization:invite'
    | 'organization:create-session'
    | 'organization:update-session'
    | 'organization:publish-session'
    | 'organization:view-session'
    | 'organization:assign-session'
    | 'organization:share-session'
    | 'organization:create-movement'
    | 'organization:update-movement'
    | 'organization:publish-movement'
    | 'organization:view-movement'
    | 'organization:share-movement'
    | 'organization:duplicate-movement'
    | 'organization:create-program'
    | 'organization:update-program'
    | 'organization:publish-program'
    | 'organization:view-program'
    | 'organization:share-program'
    | 'organization:create-form'
    | 'organization:update-form'
    | 'organization:view-form'
    | 'organization:delete-form'
    | 'organization:share-form'
    | 'organization:view-video-analyses'
    | 'organization:create-saved-filters'
    | 'organization:update-saved-filters'
    | 'organization:view-saved-filters'
    | 'organization:delete-saved-filters'
    | 'organization:create-dashboards'
    | 'organization:update-dashboards'
    | 'organization:view-dashboards'
    | 'organization:delete-dashboards'
    | 'organization:view-explorations'
    | 'organization:update-explorations'
    | 'organization:delete-explorations'
    | 'organization:create-explorations';

export type GroupActions =
    | 'group:update'
    | 'group:add-members'
    | 'group:view-members'
    | 'group:update-members'
    | 'group:invite-members'
    | 'group:manage-logs'
    | 'group:manage-calendar'
    | 'group:view-logs';

export type SessionActions =
    | 'session:view'
    | 'session:update'
    | 'session:publish'
    | 'session:assign'
    | 'session:assign-self'
    | 'session:share-partial'
    | 'session:duplicate'
    | 'session:delete'
    | 'session:share';

export type ProgramActions =
    | 'program:create'
    | 'program:view'
    | 'program:update'
    | 'program:publish'
    | 'program:delete';

export type MovementActions =
    | 'movement:create'
    | 'movement:view'
    | 'movement:update'
    | 'movement:publish'
    | 'movement:delete'
    | 'movement:share'
    | 'movement:duplicate';

export type SavedFilterActions =
    | 'saved-filter:create'
    | 'saved-filter:view'
    | 'saved-filter:update'
    | 'saved-filter:delete';

export type DashboardActions =
    | 'dashboard:create'
    | 'dashboard:view'
    | 'dashboard:update'
    | 'dashboard:delete';

export type EventActions = 'event:update' | 'event:delete';

export type IndividualActions =
    | 'individual:create'
    | 'individual:view'
    | 'individual:update'
    | 'individual:manage-logs'
    | 'individual:delete'
    | 'individual:manage-calendar'
    | 'individual:manage-submissions'
    | 'individual:manage-videos';

export type FormActions =
    | 'form:share'
    | 'form:update'
    | 'form:view'
    | 'form:delete'
    | 'form:assign'
    | 'form:assign-self'
    | 'form:duplicate';

export type Actions =
    | OrganizationActions
    | GroupActions
    | SessionActions
    | ProgramActions
    | FormActions
    | MovementActions
    | IndividualActions
    | EventActions
    | SavedFilterActions
    | DashboardActions;

export interface AssetAvatar {
    avatar: string;
}

export interface AssetVersion {
    uuid: string;
    type: AssetVersionType;
    file_mime_type: string;
    file_signed_url: string | null;
    file_size: number | null;
    height: number | null;
    width: number | null;
    framerate: number | null;
    duration: number | null;
    encoding_status: string;
    image_urls: Image_Urls;
    cookie: {
        [key: string]: string | number;
    } | null;
    tmpFileUrl?: string | undefined;
}

export interface AssetProcessingCompleted {
    asset: Asset;
}

export enum AssetPurpose {
    MovementExercise = 'movement-exercise',
    ProfilePhoto = 'profile-photo',
}

export interface Comment {
    uuid: string;
    created_at: number;
    comment: string;
    user: PublicUser;
}

export interface Asset {
    uuid: string;
    purpose: string;
    versions: Array<AssetVersion>;
    status: 'ready' | 'uploaded' | 'processing' | 'error';
    assetable_type?: string;
    assetable_uuid?: string;
    comments?: Comment[];
    video_analysis?: Analysis;
}

export enum AttributeCategory {
    Member = 'member',
    Group = 'group',
    Form = 'form',
    Other = 'other',
    Movement = 'movement',
    Session = 'session',
    Individual = 'individual',
}

export interface Attribute {
    name: string;
    category: AttributeCategory;
    sub_category: string;
    type: AttributeValueType;
    value?: string;
    choices?: Array<string>;
    organization_uuid: string;
}

export enum AttributeValueType {
    TEXT = 'text',
    NUMERIC = 'numeric',
    DATE = 'date',
    TIME = 'time',
    DATETIME = 'datetime',
    LIST = 'list',
}

export interface AttributeValue {
    name: string;
    category: AttributeCategory;
    sub_category: string;
    value: string | Array<string>;
    type: AttributeValueType;
    choices?: Array<string>;
}

export enum MovementType {
    GENERAL = 'general',
    ENDURANCE = 'endurance',
    STRENGTH = 'strength',
    CARDIO = 'cardio',
    TIMED_LONG = 'timed_long',
    TIMED_FAST = 'timed_fast',
    TIMED_STRENGTH = 'timed_strength',
}

export interface TemExercise {
    uuid: string;
    organization_uuid: string;
    title: string;
}

export interface Movement {
    uuid: string;
    object: EntityType;
    name: string;
    description: string | null;
    movement_type: MovementType;
    published_at: number | null;
    assets: Array<Asset>;
    attributes?: Array<AttributeValue>;
    organization?: Organization | null;
    movement_presets?: MovementPreset | null;
    cover_asset?: Asset;
    is_uploading?: boolean;
    is_archived?: boolean;
    organization_uuid?: string;
}

export interface PhysicalProductForPurchase {
    uuid: string;
    name: string;
    stripe_product_id: string;
    stripe_price_id: string;
    inventory_count: number;
    price: number;
}

export interface Organization {
    uuid: string;
    name: string;
    slug: string;
    about_us: string | null;
    logo_url: string | null;
    banner_url: string | null;
    video_url: string | null;
    mux_playback_id: string | undefined;
    image_urls: Image_Urls;
    object: EntityType.ORGANIZATION;
    zip?: string;
    type?: string;
    creator?: UserData;
    subscription_tier?: OrgSubscriptionTier;
}

export interface OrganizationPublic {
    uuid: string;
    name: string;
    slug: string;
    about_us: string | null;
    logo_url: string | null;
    banner_url: string | null;
    video_url: string | null;
    mux_playback_id: string | undefined;
    image_urls: Image_Urls;
    object: EntityType.ORGANIZATION;
    zip?: string;
}

export interface OrgSubscriptionTier {
    level: number;
    name: string;
    price_id: string;
    product_id: string;
    monthly_price: number;
}

export interface Session {
    uuid: string;
    title: string;
    views?: number;
    object: EntityType;
    movement_count?: number;
    description?: string;
    created_at?: number | null;
    updated_at?: number | null;
    movements?: Array<SessionMovement>;
    attributes: Array<AttributeValue>;
    duration?: number | null | string;
    published_at?: number | null;
    organization: Organization;
    cover_photo?: string | null;
    is_published?: boolean | null;
    organization_uuid?: string | undefined;
    is_favorite: boolean;
    image_urls: Image_Urls;
    archived_at: string | null;
}

export interface GlobalMessageAction {
    actionText?: string | null;
    actionLink?: string | null;
}

export interface GlobalMessage {
    uuid?: string | null;
    status: AlertColor;
    message: string;
    action?: GlobalMessageAction | null;
}

export interface RestrictedUserData {
    first_name: string;
    last_name: string;
    profile_photo: null | string;
}

export interface IndividualLimited {
    uuid: string;
    name: string;
    object: EntityType;
    organization: Organization;
    image_urls: Image_Urls;
}

export interface UserLimited {
    uuid: string;
    name: string;
    user_name: string;
    profile_photo: null | string;
    individuals: IndividualLimited[];
}

export type Fields = { [key: string]: string };

export interface UserData {
    uuid: string;
    first_name: string;
    last_name: string;
    email_verified_at: null | string;
    email: string;
    user_name: string;
    phone: string;
    profile_photo: null | string;
    phone_national: string;
    fields: Fields;
}

export interface Team {
    name: string;
    display_name: string;
    classification: ShareableClassification;
    image_urls: string;
    type?: string;
}

export interface Permission {
    name: Actions;
    display_name: string;
    classification: ShareableClassification;
    description?: string | null;
}

export interface Role {
    name: Roles;
    display_name: string;
    classification: ShareableClassification;
    description?: string | null;
}

export interface EntityPermission {
    team: Team;
    permissions: Array<Permission>;
    roles: Array<Role>;
}

export interface SharedUser {
    uuid: string;
    name: string;
    display_name: string;
    avatar_url: string;
    type: 'user' | 'group';
    role: Role;
}

export interface Contact {
    uuid: string;
    name: string;
    relationship: string;
    type: 'user' | 'email' | 'group';
    user_name?: string | null;
    first_name?: string;
    last_name?: string;
    image_urls?: Image_Urls;

    fields?: Fields;
}

export enum GroupType {
    INDIVIDUAL = 'individual',
    GROUP = 'group',
    TEAM = 'team',
}

export interface Group {
    members_with_users: number;
    uuid: string;
    name: string;
    image_urls: { [n: string]: string | null };
    type: GroupType;
    assets: Array<Asset>;
    attributes: Array<AttributeValue>;
    logo_url: string;
    object: EntityType;
    logo?: string; // when updating a group
    members?: Member[];
    members_count?: number;
    user_count?: number;
    member_counts?: { [key: string]: { count: number; name: string } };
    members_sample?: Member[];
    organization?: Organization;
    user_uuid?: string;
    user_counts?: number;
    archived_at?: string | null;
}

export interface PublicUser {
    uuid: string;
    name: string;
    profile_photo: string | null;
}

export interface Individual {
    uuid: string;
    name: string;
    profile_photo: string;
    object: EntityType;
    organization: Organization;
    attributes: AttributeValue[];
    slug: string;
    image_urls: Image_Urls;
    managing_user: Contact | null;
    managing_user_uuid: string | null;
    archived_at: number | null;
    profile_photo_path: string | null;
    groups?: Group[];
    calendars?: string[];
}

export interface MemberType {
    uuid: string;
    name: string;
}

export interface Member {
    uuid: string;
    first_name: string;
    last_name: string;
    created_at: number;
    updated_at: number;
    group: Group;
    memberType: MemberType;
    individual: Individual;
    attributes: Array<AttributeValue>;
    individual_uuid?: string | undefined;
    image_urls?: Image_Urls;
    position?: Tag | null;
    positionGroup?: Tag | null;
    user_uuid?: string | undefined;
}

export interface Image_Urls {
    avatar: string | null;
    original: string | null;
    '200': string | null;
    '350': string | null;
    '500': string | null;
    '1000': string | null;
}

export enum Time {
    TimeInSeconds = 'Time(sec)',
    TimeInMinutes = 'Time(min)',
    TimeInHours = 'Time(hours)',
}

export enum SetAttributeType {
    Reps = 'Reps',
    Load = 'Load',
    Time = 'Time',
    Distance = 'Distance',
    Rest = 'Rest',
    RangeOfMotion = 'RangeOfMotion',
    Speed = 'Speed',
    RSI = 'RSI',
    Force = 'Force',
    BodySide = 'BodySide',
    RPE = 'RPE',
    Band = 'Band',
    Acceleration = 'Acceleration',
    Power = 'Power',
    RPM = 'RPM',
    GPSPlayerLoad = 'GPSPlayerLoad',
    GPSAccelCount = 'GPSAccelCount',
    GPSDecelCount = 'GPSDecelCount',
}

export interface SetAttribute {
    type: SetAttributeType;
    subType?: Time;
    value: number | string;
    secondaryValue?: number;
}

export interface Set {
    attributes: SetAttribute[];
    logged: boolean;
    uuid?: string | null;
}

export interface MovementDetail {
    sets: Set[];
    title: string;
    attributesList: SetAttributeType[];
    logged: boolean;
    uuid: string;
}

export type MovementPreset = Record<string, string | null>;

export interface NewMovementSet {
    attributes: SetAttribute[];
    uuid: string;
}

export interface NewMovement {
    uuid: string;
    name: string;
    thumbnail: string;
    sets: NewMovementSet[];
    attributesList: SetAttributeType[];
}

export type ShareableClassification =
    | 'session'
    | 'program'
    | 'movement'
    | 'organization'
    | 'form'
    | 'group'
    | 'individual'
    | 'event'
    | 'individuals'
    | 'saved-filter'
    | 'dashboard';

export type Roles =
    | 'form-admin'
    | 'form-manager'
    | 'form-viewer'
    | 'form-athlete'
    | 'group-admin'
    | 'group-athlete'
    | 'group-coach'
    | 'group-family-member'
    | 'group-trainer'
    | 'group-volunteer'
    | 'movement-manager'
    | 'movement-admin'
    | 'movement-viewer'
    | 'organization-admin'
    | 'organization-content-manager'
    | 'organization-coach'
    | 'program-admin'
    | 'program-manager'
    | 'program-viewer'
    | 'session-manager'
    | 'session-athlete'
    | 'session-admin'
    | 'session-viewer'
    | 'individual-family';

export interface NewSessionMovement {
    movement: string;
    reps?: number;
    group_title?: string;
    load_unit?: 'lb';
    load_value?: number;
    distance_unit?: 'km' | 'm';
    distance_value?: number;
    time_unit?: 's' | 'm' | 'h' | 'ms';
    time_display_format?: string;
    time_value?: number;
    rest_unit?: 's' | 'm' | 'h';
    rest_value?: number;
    weight_unit?: 'kg' | 'gm';
    weight_value?: number;
    range_of_motion_value?: number;
    range_of_motion_unit?: 'degree';
    speed_value?: number;
    speed_unit?: 'km/h' | 'm/s' | 'm/h';
    body_side?: BodySide | null;
    rsi_value?: number;
    rpm_value?: number;
    gps_player_load_value?: number;
    gps_accel_count_value?: number;
    gps_decel_count_value?: number;
}

export interface UploadedSession {
    uuid: string;
    title: string;
    duration: number;
    movement_count: number;
    views: number;
    movements: NewSessionMovement[];
    attributes: Attribute[];
}

export interface SessionGoal {
    uuid: string;
    created_at: number;
    updated_at: number;
    order: number;
    reps: number | null;
    group_title?: string | null;
    load_unit: string;
    load_value: number | null;
    distance_unit: string;
    distance_value: number | null;
    time_unit: string;
    time_display_format: string;
    time_value: number | null;
    rest_unit: string;
    rest_value: number | null;
    weight_unit: string;
    weight_value: number | null;
    range_of_motion_value: number | null;
    range_of_motion_unit: string;
    speed_value: number | null;
    speed_unit: string;
    acceleration_value: any;
    acceleration_unit: AccelerationUnit;
    power_value: any;
    power_unit: PowerUnit;
    rsi_value: number | null;
    force_value: number | null;
    force_unit: string;
    body_side: BodySide | null;
    rpe_value: number | null;
    band_value: number | null;
    rpm_value: number | null;
    gps_player_load_value: number | null;
    gps_accel_count_value: number | null;
    gps_decel_count_value: number | null;
}

export interface SessionMovement {
    cover_asset?: Asset;
    is_uploading: unknown | Boolean;
    uuid: string;
    name: string;
    object: EntityType;
    description: string;
    movement_type: string;
    published_at: number | null;
    attributes?: Array<AttributeValue>;
    assets: Array<Asset>;
    pivot: SessionGoal;
}

export interface MovementLogReq {
    uuid?: number | string;
    local_timezone?: string;
    movement: string;
    load_value?: number;
    load_unit?: string;
    distance_value?: number;
    distance_unit?: string;
    time_value?: number;
    time_unit?: string;
    time_display_format?: string;
    weight_value?: number;
    weight_unit?: string;
    reps?: number;
    range_of_motion_value?: number;
    range_of_motion_unit?: string;
    speed_value?: number;
    speed_unit?: string;
    acceleration_value?: number;
    acceleration_unit?: string;
    power_value?: number;
    power_unit?: string;
    rsi_value?: number | null;
    rpm_value?: number | null;
    force_value?: number | null;
    force_unit?: string;
    body_side?: BodySide | null;
    gps_player_load_value?: number | null;
    gps_accel_count_value?: number | null;
    gps_decel_count_value?: number | null;
    marked_as_complete?: boolean | undefined;
}

export interface MovementLogRes {
    uuid: string;
    created_at: number;
    time_value?: number;
    time_unit?: string;
    time_display_format?: string;
    load_value?: number;
    load_unit?: string;
    distance_value?: number;
    distance_unit?: string;
    reps?: number;
    weight_value?: number;
    weight_unit?: string;
    start_flag: boolean;
    complete_flag: boolean;
    range_of_motion_value?: number;
    range_of_motion_unit?: string;
    speed_value?: number;
    speed_unit?: string;
    acceleration_value?: number;
    acceleration_unit?: string;
    power_value?: number;
    power_unit?: string;
    body_side?: BodySide | null;
    marked_as_complete: boolean;
    rsi_value?: number | null;
    rpm_value?: number | null;
    gps_player_load_value?: number | null;
    gps_accel_count_value?: number | null;
    gps_decel_count_value?: number | null;
}

export interface Activity {
    uuid: string;
    started_at: number;
    ended_at?: any;
    archived_at: number | null;
    date: string | null;
    logs: Log[];
    name: string;
    src: string;
    individual?: Individual;
    notes?: string;
    session?: Session | null;
}

export interface LogDto {
    markedAsComplete: boolean;
    uuid: string;
    movement: Movement;
    movementUuid: string;
    timeValue: number | null;
    timeUnit: number | null;
    timeDisplayFormat: number | null;
    loadValue: number | null;
    loadUnit: number | null;
    distanceValue: number | null;
    distanceUnit: number | null;
    speedValue: number | null;
    speedUnit: number | null;
    forceValue: number | null;
    forceUnit: number | null;
    accelerationValue: number | null;
    accelerationUnit: number | null;
    powerValue: number | null;
    powerUnit: number | null;
    rangeOfMotionValue: number | null;
    rangeOfMotionUnit: number | null;
    bodySide?: BodySide | null;
    reps: number | null;
    rpeValue: number | null;
    bandValue: number | null;
    restValue: number | null;
    restUnit: string | null;
    mediaUrl?: string | null;
    rsiValue?: number | null;
    rpmValue?: number | null;
    gpsPlayerLoadValue?: number | null;
    gpsAccelCountValue?: number | null;
    gpsDecelCountValue?: number | null;
    mediaType?: 'video' | 'image' | null | undefined;
}

export interface LogSet {
    movement: Movement;
    mediaUrl?: string | null;
    mediaType?: 'video' | 'image' | null | undefined;
    logs: Array<LogDto>;
}

export interface Log {
    uuid: string | null;
    created_at: number | null;
    time_value?: number | null;
    time_unit?: string | null;
    time_display_format?: string;
    time_goal_value?: number | null;
    time_goal_unit?: string | null;
    time_goal_display_format?: string | null;
    rest_value?: number | null;
    rest_unit?: string | null;
    rest_goal_value?: number | null;
    rest_goal_unit?: string | null;
    load_value?: number | null;
    load_unit?: string | null;
    load_goal_value?: number | null;
    load_goal_unit?: string | null;
    distance_value?: number | null;
    distance_unit?: string | null;
    distance_goal_value?: number | null;
    distance_goal_unit?: string | null;
    acceleration_value?: number | null;
    acceleration_unit?: string | null;
    acceleration_goal_value?: number | null;
    acceleration_goal_unit?: string | null;
    power_value?: number | null;
    power_unit?: string | null;
    power_goal_value?: number | null;
    power_goal_unit?: string | null;
    reps?: number | null;
    reps_goal?: number | null;
    range_of_motion_value?: number | null;
    range_of_motion_unit?: string;
    speed_value?: number | null;
    speed_unit?: string;
    range_of_motion_goal_value?: number | null;
    range_of_motion_goal_unit?: string;
    speed_goal_value?: number | null;
    speed_goal_unit?: string;
    rsi_value?: number | null;
    rsi_goal_value?: number | null;
    rpm_value?: number | null;
    rpm_goal_value?: number | null;
    force_value?: number | null;
    force_unit?: string;
    force_goal_value?: number | null;
    force_goal_unit?: string;
    marked_as_complete?: boolean | null;
    movement: Movement;
    order?: number | undefined;
    is_from_original_session?: boolean | undefined;
    body_side?: BodySide | null;
    body_side_goal?: BodySide | null;
    rpe_value?: number | null;
    rpe_goal_value?: number | null;
    band_value?: number | null;
    band_goal_value?: number | null;
    gps_player_load_goal_value?: number | null;
    gps_player_load_value?: number | null;
    gps_accel_count_goal_value?: number | null;
    gps_accel_count_value?: number | null;
    gps_decel_count_goal_value?: number | null;
    gps_decel_count_value?: number | null;
    group_title?: string | null;
}

export const RPEOptions: Record<number, string> = {
    1: 'Very Light',
    2: 'Light',
    3: 'Moderate',
    4: 'Hard',
    5: 'Max Effort',
};

export const BandOptions: Record<number, string> = {
    1: 'Very Light',
    2: 'Light',
    3: 'Medium',
    4: 'Heavy',
    5: 'Extra Heavy',
};

export type BodySide = 'left' | 'right' | 'both';

export interface PartialUpload {
    progress: number;
    originalFileName: string;
    name: string;
    stats?: Evaporate.TransferStats;
    entityId: string | null;
    entityType: EntityType | null;
    encodingOptions: EncodingOptionsPayload;
}

export interface PartialUploadList {
    [key: string]: PartialUpload;
}

export enum EntityType {
    MOVEMENT = 'movement',
    SESSION = 'session',
    PROGRAM = 'program',
    ORGANIZATION = 'organization',
    FORM = 'form',
    GROUP = 'group',
    INDIVIDUAL = 'individual',
    Event = 'event',
    SavedFilter = 'saved-filter',
    Dashboard = 'dashboard',
    // POD = 'pod', @todo is this needed?
}

export interface DashboardCount {
    movements_draft: number;
    movements_published: number;
    programs: number;
    sessions_draft: number;
    sessions_published: number;
}

export enum QuestionType {
    MultipleChoiceSingleSelect = 'multiple-choice-single-select',
    MultipleChoiceMultiSelect = 'multiple-choice-multi-select',
    Text = 'text',
}

export interface Link {
    uuid: string;
    shareable_type: ShareableClassification;
    shareable_uuid: string;
    title: string | null;
    description: string | null;
    image_url: string | null;
    role: Role;
    code?: string;
    has_code: boolean;
    secondary_title?: string;
    secondary_image_url?: string;
}

export interface PublicIndividual {
    name: string;
    image_urls: Image_Urls;
    can_accept: boolean;
    organization: Organization;
}

export interface Dimensions {
    id: string;
    name: string;
    image_url: string | null;
    type: string;
    type_display: string;
}

export type TimelineType =
    | 'group.created'
    | 'individual.created'
    | 'organization.created'
    | 'form.submitted'
    | 'program.added.to.calendar'
    | 'session.activity.started'
    | 'session.activity.ended';

export interface TimelineEntries {
    id: number;
    type: TimelineType;
    reference_date: number;
    reference_date_timezone: string;
    start_time_utc: number;
    start_time: number;
    start_time_timezone: string;
    start_time_day: string;
    start_time_week: string;
    end_time_utc: number | null;
    end_time: number | null;
    end_time_timezone: number | null;
    end_time_day: string | null;
    end_time_week: number | null;
    actionable_name: string;
    actionable_id: string;
    actionable_source: string;
    timelineable_name: string;
    timelineable_id: string;
    timelineable_source: string;
    user_first_name: string;
    user_last_name: string;
    group_name: string | null;
    group_id: string | null;
    group_owner_id: string | null;
    program_name: string;
    program_id: string | null;
    individual_name: string;
    individual_id: string | null;
    individual_owner_id: string | null;
    individual_secondary_owner_id: string | null;
    individual_organization_id: string | null;
    form_name: string;
    form_submitted_at: number;
    form_submitted_values: Array<{ section: any; question: any; value: string }>;
    form_id: string | null;
    session_name: string;
    user_slug: string;
    session_id: string | null;
    organization_name: string;
    organization_id: string | null;
    organization_owner_id: string | null;
    organization_photo_url: string | null;
    degenerate_id: string | null;
    event_starts_at: number | null;
    event_starts_at_timezone: string | null;
    event_ends_at: number | null;
    event_ends_at_timezone: string | null;
    activity_id: string | null;
    activity_started_at: number | null;
    activity_started_at_local_timezone: string | null;
    activity_ended_at: number | null;
    activity_ended_at_local_timezone: string | null;
    activity_logs: Array<any>;
}

export interface TimelineEntriesResponse extends ResponseWithPagination<TimelineEntries> {
    source_id: string;
    source: string;
    source_type: string;
    name: string;
    image_url?: string | null;
}

export interface Invite {
    uuid: string;
    shareable_title: string;
    email: string;
    status: string;
    created_at: number;
    role: Role;
    sent_by_name: string;
}

export interface ShareableInvite {
    uuid: string;
    shareable_type: ShareableClassification;
    shareable_uuid: string;
    title: string | null;
    description: string | null;
    image_url: string | null;
    role: Role;
    sent_by_name: string;
}

export interface LocationState {
    referrer: string;
    referrer_message: string | null;
    action: GlobalMessageAction | null;
}

export interface Program {
    uuid?: string;
    name: string;
    object: EntityType;
    sessions: Array<Session>;
    organization: Organization;
}

export interface Submission {
    uuid?: string;
    submitted_at: number;
    form: Form;
    name: string;
    thumbnail: string;
    values: Array<SubmissionValue>;
    score: number;
    score_numerator: number;
    score_denominator: number;
    individual?: Individual;
}

export interface SubmissionValue {
    created_at?: number;
    updated_at?: number;
    question: Question;
    text_value: string;
    question_version: number;
    section: Section | null;
}

export type Form = {
    uuid: string;
    created_at?: number;
    updated_at?: number;
    title: string;
    type?: string;
    description: string;
    sections: Array<Section>;
    completed_submissions_count: number;
    media_url?: string | null;
    media?: string | null;
    image_urls: Image_Urls;
    attributes?: Array<AttributeValue>;
    asset?: Asset | null;
    object: EntityType;
    organization: Organization;
};

export type Section = {
    uuid: string;
    name: string;
    description?: string | undefined;
    questions: Array<Question>;
    asset?: Asset | null;
    media?: string | null;
    order?: number | undefined;
};

export type Question = {
    uuid?: string;
    question: string;
    type: string;
    asset?: Asset | null;
    created_at?: number;
    updated_at?: number;
    version_number?: number;
    choices?: Array<Choice>;
    pivot?: {
        created_at?: number;
        updated_at?: number;
        required: boolean;
    };
    media?: string | null;
    order: number;
};

export enum MediaType {
    image = 'image',
    video = 'video',
    imageOrVideo = 'imageOrVideo',
    file = 'file',
}

export interface UploadedMedia {
    uuid: string;
    type: string;
    tmpFileUrl: string;
    fileDataUrl: string | null;
    uploadProgress?: number | null;
    originalName: string;
    uploadedFile: File;
}

export interface Choice {
    uuid: string;
    text: string;
    media_url?: string | null;
    media?: string | null;
    is_answer?: boolean | undefined;
}

export interface SubNavBarTab {
    label: string;
    path: string;
    search?: string;
}

export interface SubmissionQuestion {
    question: string;
    answer: string;
    choice?: string | null;
}

export interface PaginationLink {
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
}

export interface Meta {
    path: string;
    per_page: number;
    current_page: number;
}

export interface AllSubmissionsResponse {
    data: Submission[];
    links: PaginationLink;
    meta: Meta;
}

export interface groupedByDateSubmissions {
    [key: string]: Array<Submission>;
}

export interface GroupedQuestionsSection {
    name: string;
    questions: Array<SubmissionQuestion>;
}

export interface CompletedAssessmentSections {
    [key: string]: GroupedQuestionsSection;
}

export interface SpotlightResult {
    resultType: string;
    resultId: string;
    resultText: string;
    resultSubtext: string;
    resultCaption: string;
    avatarUrl: string;
}

export interface SearchToken {
    key: string;
    expires: number;
}

export interface SearchedMovement {
    id: string; //uuid
    name: string;
    movementType: string;
    description?: string | null;
    organizationId?: string | null;
    organizationName?: string | null;
    organizationLogoUrl?: string | null;
    objectAvatarPhoto?: string | null;
    _tags?: any;
}

export interface SearchedGroup {
    id: string; //uuid
    name: string;
    type: string;
    description?: string | null;
    organizationName?: string | null;
    organizationLogoUrl?: string | null;
    objectAvatarPhoto?: string | null;
    groupIds: string[];
    _tags?: any;
}

export enum LoadUnit {
    Pound = 'lb',
    Kilogram = 'kg',
    Gram = 'g',
}

export enum RangeOfMotionUnit {
    Degree = 'degree',
}

export enum SpeedUnit {
    MPH = 'm/h',
    KPH = 'km/h',
    MS = 'm/s',
}

export enum AccelerationUnit {
    FPS = 'ft/s2',
}

export enum PowerUnit {
    Watt = 'w',
}

export enum ForceUnit {
    Newton = 'n',
}

export enum DistanceUnit {
    Centimeter = 'cm',
    Meter = 'm',
    Kilometer = 'km',
    Yard = 'yd',
    Inch = 'in',
    Feet = 'ft',
    Mile = 'mi',
    Millimeter = 'mm',
}

export enum TimeDisplayFormat {
    Second = 'ss',
    Meet = 'ss.SSS',
    Run = 'mm:ss.SSS',
    Common = 'mm:ss',
    Duration = 'hh:mm:ss',
    DurationWithPrecision = 'hh:mm:ss.SSS',
}

export enum TimeUnit {
    Second = 's',
    Millisecond = 'ms',
}

export interface ResponseWithPagination<T> {
    data: T[];
    links: PaginationLink;
    meta: Meta;
}

export interface Calendar {
    uuid: string;
    name: string;
}

export interface SessionStackCreateRequest {
    program?: string;
    sessions: Array<{
        uuid: string;
        start_date_time: string;
        start_timezone: string;
        end_date_time: string | null;
        end_timezone: string | null;
    }>;
}

export interface CalendarEvent {
    object: 'event';
    owner: string;
    uuid: string;
    starts_at: string;
    starts_at_timestamp: number;
    starts_at_timezone: string;
    started_at: number;
    ends_at: string | null;
    ends_at_timestamp: number | null;
    ends_at_timezone: string;
    ended_at: number;
    managing_user_name: string | null;
    managing_user_photo: string | null;
    title: string;
    form_name: string;
    form_id: string | null;
    form_photo: string | null;
    individual_name: string;
    individual_id: string;
    group_name: string | null;
    group_id: string | null;
    session_name: string;
    session_photo: string;
    session_id: string | null;
    program_name: string;
    program_id: string;
    organization_name: string;
    organization_photo: string | null;
}

export interface FullCalendarEvent {
    id: string;
    start: string;
    end?: string;
    title: string;
    allDay?: boolean;
    individual_name?: string;
    organization_photo?: string | null;
    organization_name?: string;
}

export interface UserProfile {
    slug: string;
    name: string;
    profile_photo: Image_Urls;
    bio: string | null;
    followers_count: number;
    following_count: number;
}

export enum Navigation {
    EMPTY = '',

    HOME = 'home',

    DATA = 'data',
    LEARN = 'class',
    TRAIN = 'train',
    COMMUNITY = 'community',
    SOCIAL = 'social',
    PLAN = 'plan',
    EXPLORE = 'explore',
    ADMIN = 'admin',

    LEARN_DASHBOARD = 'learn-dashboard',
    LEARN_ASSESSMENTS = 'learn-assessments',

    TRAIN_DASHBOARD = 'train-dashboard',
    TRAIN_MOVEMENTS = 'train-movements',
    TRAIN_SESSIONS = 'train-sessions',

    COMMUNITY_GROUPS_TEAMS = 'commiunity-groups-teams',
    COMMUNITY_REPORTS = 'community-reports',
    COMMUNITY_INDIVIDUALS = 'commiunity-individuals',
    COMMUNITY_DASHBOARDS = 'community-dashboards',

    SOCIAL_FEEDS = 'social-feeds',
    SOCIAL_POSTS = 'social-posts',

    PLAN_DASHBOARD = 'plan-dashboard',
    PLAN_TIMELINE = 'plan-timeline',
    PLAN_CALENDAR = 'plan-calendar',
    PLAN_REPORTS = 'plan-reports',

    NETWORKING_TREE = 'networking-tree',

    ADMIN_ORDERS = 'admin_orders',
    ADMIN_PODS = 'admin_pods',
    ADMIN_PRODUCTS = 'admin_products',
    ADMIN_SUBSCRIPTIONS = 'admin_subscriptions',
    ADMIN_COMMISSIONS = 'admin_commissions',
}

export interface SupportTeam {
    uuid: string;
    revoked: boolean;
    organization: Organization;
    user: UserLimited;
}

export interface DateValue {
    date: DateTime | null;
    time: DateTime | null;
    timezone: TimezoneKey | null;
    isWholeDay: boolean;
}

export interface CountSearchDto {
    'filter[event.organization.source_id]'?: string;
    'filter[is_assessment]'?: string;
}

export interface DashboardCounts {
    complete: number;
    upcoming: number;
    overdue: number;
}

export interface Analysis {
    individual_name: string;
    uuid: string;
    organization_uuid: string;
    provider_id: string;
    provider_type: 'guru';
    analysis: {
        reason?: 'FPS_TOO_LOW' | 'INSUFFICIENT_JOINT_DATA';
        status?: 'Pending' | 'Complete' | 'Failed';
        videoFrameRate?: number;
    };
    fps: number;
    status: 'Pending' | 'Complete' | 'Failed';
    session_date_time: string;
    session_date_time_local: string;
    asset: Asset;
    individual_uuid: string;
    runnerProgress: Array<{ timestamp: number; frameIndex: number; distanceFromStart: number }>;
    summary_ten_yard_time: string;
    summary_ten_yard_speed: string;
    video_analysis_key: number;
}

export interface DimAnalysis {
    uuid: string;
    individual_name: string;
    summary_ten_yard_time: string;
    summary_ten_yard_speed: string;
    video_analysis_key: number;
}
