import { Box, FormControlLabel, FormGroup, Snackbar, Typography, useTheme } from '@mui/material';
import { createOrganization, getSignedUrl, uploadFile, userRegister, viewOrganization } from 'api';
import { AxiosError } from 'axios';
import { CustomStepper, MaskedInput, RadioGroupInput, UploadLogo } from 'components';
import { Field } from 'formik';
import { Switch as FormikMuiSwitch, TextField as FormikMuiTextField } from 'formik-mui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Organization, yupSchemas } from 'utils';
import createCheckoutSession from '../../../api/Auth/createCheckoutSession';
import userUsernameCheck from '../../../api/Auth/userUsernameCheck';
import ChooseOrganizationPlanRadioGroup from '../../../components/Inputs/ChooseOrganizationPlanRadioGroup';
import bronzeMedal from '../../../images/bronze-medal.png';
import goldMedal from '../../../images/gold-medal (1).png';
import silverMedal from '../../../images/silver-medal.png';
import { authSelector, registerUser } from '../../../redux/reducers/auth';
import { getUserData } from '../../../redux/reducers/user';
import { useAppDispatch } from '../../../redux/store';

interface OrganizationValues {
    name: string;
    zip: string;
    organizationLogo: string;
    organizationType: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    user_name: string;
    password: string;
    password_confirmation: string;
    selected_price: string;
    owner: boolean;
}

interface UserRegistrationValues {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    password_confirmation: string;
    phone: string;
    user_name: string;
    name: string;
    zip: string;
    organizationType: string;
    organizationLogo?: string; // optional
}

const CreateOrganization = () => {
    const { push } = useHistory();
    const { id } = useParams<{ id: string }>();

    const options = [
        { label: 'Professional', value: 'professional' },
        { label: 'College', value: 'college' },
        { label: 'High School', value: 'high-school' },
        { label: 'Travel/Club', value: 'travel' },
        { label: 'Athletic Training/Therapy', value: 'athletic-training' },
        { label: 'Private Instruction', value: 'private' },
        { label: 'Club', value: 'club' },
        { label: 'League', value: 'league' },
    ];

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState<Set<number>>(new Set());
    const [uploadLogo, setUploadLogo] = useState<File>();
    const [selectedPlan, setSelectedPlan] = useState<string>(
        process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE as string,
    );
    const [selectedOrg, setSelectedOrg] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [invitingOrganization, setInvitingOrganization] = useState<Organization>();
    const { isAuth } = useSelector(authSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const organizationResponse = await viewOrganization(id);
                    setInvitingOrganization(organizationResponse.data);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [id]);

    const uploadLogoOnS3 = async (logo: File) => {
        try {
            const response = await getSignedUrl();
            if (response.status === 201) {
                const res = await uploadFile(response.data.url, logo);
                if (res.status === 200) {
                    return response.data.key;
                }
            }
            return false;
        } catch (err: any) {
            const error: AxiosError<{ message: string }> = err;
            setError(error.message);
            return false;
        }
    };

    const checkUsername = async (values: UserRegistrationValues) => {
        try {
            const result = await userUsernameCheck({
                user_name: values.user_name,
                phone: values.phone,
                email: values.email,
            });

            if (result.data.unique) {
                return true;
            } else {
                setError('Username already exists in 1Tul. Try another username.');
                return false;
            }
        } catch (error: any) {
            setError(error.response.data.message || 'An error occurred. Please try again.');
            return false;
        }
    };

    const handleUserRegistration = useCallback(
        async (isAuth: boolean, values: UserRegistrationValues) => {
            if (!isAuth) {
                const result = await dispatch(registerUser(values));

                if (!registerUser.fulfilled.match(result)) {
                    if (result.payload && 'errors' in result.payload) {
                        setError(result.payload.message || '');
                        throw new Error('Registration failed');
                    }
                } else {
                    await dispatch(getUserData());
                    return result.payload.user;
                }
            } else {
                const userResult = await userRegister(
                    values.email,
                    values.firstName,
                    values.lastName,
                    values.password,
                    values.password_confirmation,
                    values.phone,
                    values.user_name,
                    true,
                );

                return userResult.data.user;
            }
        },
        [dispatch],
    );

    const handleSubmit = useCallback(
        async (values: OrganizationValues) => {
            setLoading(true);
            try {
                const userResponse = await handleUserRegistration(isAuth, values);

                if (uploadLogo) {
                    const key = await uploadLogoOnS3(uploadLogo);
                    if (key) {
                        values.organizationLogo = key;
                    }
                }

                const orgResponse = await createOrganization(
                    values.name,
                    values.zip,
                    values.organizationType,
                    values.organizationLogo ?? undefined,
                    invitingOrganization?.uuid,
                    userResponse?.uuid,
                    values.owner,
                );

                // generate stripe Checkout link
                const checkoutSession = await createCheckoutSession(
                    `${process.env.REACT_APP_URL}/networking/${invitingOrganization?.uuid}`,
                    orgResponse.data.uuid,
                    selectedPlan,
                );

                if (checkoutSession.data.url) {
                    window.location.href = checkoutSession.data.url;
                    return false;
                }

                return true;
            } catch (err: any) {
                const error: AxiosError<{ message: string }> = err;
                setError(error.response?.data?.message ?? 'An error occurred. Please try again.');
                return false;
            } finally {
                setLoading(false);
            }
        },
        [invitingOrganization?.uuid, selectedPlan, isAuth, handleUserRegistration, uploadLogo],
    );

    const theme = useTheme();
    return (
        <Box
            sx={{
                flex: 1,
                height: '100%',
                backgroundColor: 'white',
            }}
        >
            <CustomStepper
                skipped={skipped}
                setSkipped={setSkipped}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                initialValues={{
                    name: '',
                    zip: '',
                    organizationLogo: '',
                    organizationType: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    user_name: '',
                    password: '',
                    password_confirmation: '',
                    owner: true,
                }}
                steps={[
                    {
                        optional: false,
                        label: 'Choose Type',
                        validationSchema: yupSchemas.createOrganization.pick(['organizationType']),
                        onNext: async (values: OrganizationValues) => {
                            const type = options.find(
                                (item) => item.value === values.organizationType,
                            );
                            setSelectedOrg(type?.label || '');
                            setActiveStep(activeStep + 1);
                        },
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Your Sponsor: {invitingOrganization?.name}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Get Started by CHOOSING YOUR ORGANIZATION TYPE
                                    </Typography>
                                    <Field
                                        name="organizationType"
                                        options={options}
                                        sx={{
                                            fontWeight: 600,
                                            borderRadius: 5,
                                            border: '1px solid',
                                            borderColor: '#E3E3E3',
                                            margin: theme.spacing(2, 0),
                                            paddingLeft: theme.spacing(8),
                                            paddingRight: theme.spacing(8),
                                        }}
                                        component={RadioGroupInput}
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: false,
                        label: 'Personal',
                        validationSchema: yupSchemas.registration,
                        onNext: async (values: any) => {
                            const response = await checkUsername(values);
                            if (response) {
                                // setActiveStep(activeStep + 1);
                            }
                            return response;
                        },
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Personal Information & Login
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        The personal information will be used to create a 1Tul
                                        account.
                                    </Typography>
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="firstName"
                                        id="firstName"
                                        label="First Name"
                                        variant="filled"
                                        disabled={loading}
                                        component={FormikMuiTextField}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="lastName"
                                        id="lastName"
                                        label="Last Name"
                                        variant="filled"
                                        disabled={loading}
                                        component={FormikMuiTextField}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="phone"
                                        id="phone"
                                        label="Phone Number"
                                        mask="(999)999-9999"
                                        variant="filled"
                                        disabled={loading}
                                        component={MaskedInput}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="email"
                                        id="email"
                                        label="Email"
                                        variant="filled"
                                        disabled={loading}
                                        component={FormikMuiTextField}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="user_name"
                                        id="user_name"
                                        label="User Name"
                                        variant="filled"
                                        disabled={loading}
                                        component={FormikMuiTextField}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="password"
                                        id="password"
                                        label="Password"
                                        type="password"
                                        variant="filled"
                                        disabled={loading}
                                        component={FormikMuiTextField}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="password_confirmation"
                                        id="password_confirmation"
                                        label="Confirm Password"
                                        variant="filled"
                                        type="password"
                                        disabled={loading}
                                        component={FormikMuiTextField}
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: false,
                        validationSchema: yupSchemas.createOrganization.pick([
                            'name',
                            'zip',
                            'owner',
                        ]),
                        label: 'Create Organization',
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {`Enter A Name And Zip Code For Your  ${selectedOrg} Organization`}
                                    </Typography>
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="name"
                                        id="name"
                                        label="Organization Name"
                                        variant="filled"
                                        component={FormikMuiTextField}
                                        disabled={loading}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="zip"
                                        id="zip"
                                        label="Zip Code"
                                        variant="filled"
                                        component={FormikMuiTextField}
                                        disabled={loading}
                                    />
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    component={FormikMuiSwitch}
                                                    type="checkbox"
                                                    name="owner"
                                                    label="I am the owner of this organization"
                                                />
                                            }
                                            label="I am the owner of this organization"
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: true,
                        disabled: !uploadLogo,
                        loading: loading,
                        label: 'Upload Logo',
                        validationSchema: yupSchemas.createOrganization.pick(['organizationLogo']),
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {`Upload A Logo (optional)`}
                                    </Typography>
                                    <UploadLogo
                                        loading={loading}
                                        uploadImage={uploadLogo}
                                        setUploadImage={setUploadLogo}
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: false,
                        loading: loading,
                        onNext: async (values: OrganizationValues) => {
                            const response = await handleSubmit(values);
                            if (response) {
                                push('/home');
                            }
                            return response;
                        },
                        label: 'Choose Plan',
                        validationSchema: yupSchemas.createOrganization.pick(['organizationLogo']),
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Choose A Plan
                                    </Typography>
                                    <ChooseOrganizationPlanRadioGroup
                                        initialValue={selectedPlan}
                                        onSelected={(value) => {
                                            setSelectedPlan(value);
                                        }}
                                        options={[
                                            {
                                                image: bronzeMedal,
                                                label: 'Bronze',
                                                value: process.env
                                                    .REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE as string,
                                                description:
                                                    'GPS csv upload, 1080 API, Train, Community, Plan',
                                                price: '$25',
                                            },
                                            {
                                                image: silverMedal,
                                                label: 'Silver',
                                                value: process.env
                                                    .REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE as string,
                                                description: (
                                                    <span>
                                                        🚀 GPS API, 1080 API, Train, Community,
                                                        Plan,{' '}
                                                        <strong>
                                                            Marketplace-Local, 🔥FiyrApp
                                                        </strong>
                                                    </span>
                                                ),
                                                price: '$50',
                                            },
                                            {
                                                image: goldMedal,
                                                label: 'Gold',
                                                value: process.env
                                                    .REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE as string,
                                                description: (
                                                    <span>
                                                        🚀 GPS API, 1080 API, Train, Community,
                                                        Plan,{' '}
                                                        <strong>
                                                            Marketplace-Global, 🤖Unlimited Video
                                                            AI, Learn, Explore, 🔥FiyrApp
                                                        </strong>
                                                    </span>
                                                ),
                                                price: '$100',
                                            },
                                        ]}
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                ]}
            />
            <Snackbar
                open={!!error}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={error}
                onClose={() => {
                    setError('');
                }}
                autoHideDuration={6000}
            />
        </Box>
    );
};

export default CreateOrganization;
