import { Box, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ButtonWithLoader, CustomButton } from 'components/Buttons';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';

interface StepperProps {
    steps: Array<{
        label: string;
        onNext?: Function;
        onSkip?: Function;
        disabled?: boolean;
        optional: boolean;
        loading?: boolean;
        validationSchema?: any;
        content: React.ReactNode;
    }>;
    stepperClassName?: string;
    initialValues?: any;
    showOptional?: boolean;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    skipped: Set<number>;
    setSkipped: React.Dispatch<React.SetStateAction<Set<number>>>;
    stepperTitle?: string;
}

const CustomStepper = ({
    steps,
    skipped,
    activeStep,
    setSkipped,
    showOptional,
    setActiveStep,
    initialValues,
    stepperTitle,
}: StepperProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const isStepOptional = (step: number) => !!steps[step] && steps[step].optional;

    const isNextDisabled = (step: number) => !!steps[step] && steps[step].disabled;

    const isStepSkipped = (step: number) => skipped.has(step);

    const onNextOrSkip = async (values: any) => {
        let response = true;
        const currentStep = steps[activeStep];

        if (currentStep?.onNext && !(isStepOptional(activeStep) && isNextDisabled(activeStep))) {
            response = (await currentStep.onNext(values)) ?? false;
        } else if (currentStep?.onSkip) {
            response = (await currentStep.onSkip(values)) ?? false;
        }

        if (response) {
            if (currentStep.optional) {
                handleSkip();
            } else {
                handleNext(activeStep);
            }
        }
    };

    const handleNext = async (step: number) => {
        const currentStep = steps[step];

        if (currentStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

            if (currentStep.optional && skipped.has(step)) {
                let newSkipped = new Set(skipped.values());
                newSkipped.delete(step);
                setSkipped(newSkipped);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = async () => {
        const currentStep = steps[activeStep];

        if (currentStep) {
            if (!isStepOptional(activeStep)) alert("You can't skip a step that isn't optional.");
            else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped((prevSkipped) => {
                    const newSkipped = new Set(prevSkipped.values());
                    newSkipped.add(activeStep);
                    return newSkipped;
                });
            }
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ margin: theme.spacing(9, '15%') }}>
                <Stepper
                    activeStep={activeStep}
                    sx={{
                        padding: 0,
                        width: {
                            xs: '100%',
                            md: 'auto',
                        },
                    }}
                >
                    {steps.map(({ label }, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: ReactNode } = {};
                        if (isStepOptional(index) && showOptional) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{!matches && label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {stepperTitle && (
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                            marginTop: theme.spacing(4),
                            marginBottom: theme.spacing(4),
                        }}
                    >
                        {stepperTitle}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', flex: 1 }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={steps[activeStep].validationSchema}
                    onSubmit={(values) => {
                        onNextOrSkip(values);
                    }}
                >
                    {({ isValid, dirty, values }) => (
                        <Form
                            style={{
                                flex: 1,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {!!steps[activeStep] && steps[activeStep].content}
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginTop: theme.spacing(10),
                                    padding: theme.spacing(12, 24),
                                    justifyContent: 'space-between',
                                    borderTop: '1px solid #EBEBEB',

                                    [theme.breakpoints.down('md')]: {
                                        padding: theme.spacing(8),
                                    },
                                }}
                            >
                                <CustomButton
                                    size="large"
                                    variant="outlined"
                                    onClick={handleBack}
                                    disabled={activeStep === 0 || steps[activeStep].loading}
                                    sx={{
                                        minWidth: 160,
                                        marginRight: theme.spacing(1),

                                        [theme.breakpoints.down('md')]: {
                                            minWidth: 120,
                                        },
                                    }}
                                >
                                    Back
                                </CustomButton>
                                {isStepOptional(activeStep) && isNextDisabled(activeStep) ? (
                                    <ButtonWithLoader
                                        color="primary"
                                        variant="outlined"
                                        type="submit"
                                        sx={{
                                            minWidth: 160,
                                            marginRight: theme.spacing(1),

                                            [theme.breakpoints.down('md')]: {
                                                minWidth: 120,
                                            },
                                        }}
                                        loading={steps[activeStep].loading}
                                        disabled={steps[activeStep].loading}
                                    >
                                        Skip
                                    </ButtonWithLoader>
                                ) : activeStep === steps.length - 1 ? (
                                    <ButtonWithLoader
                                        color="primary"
                                        variant="contained"
                                        type={'submit'}
                                        sx={{
                                            minWidth: 160,
                                            marginRight: theme.spacing(1),

                                            [theme.breakpoints.down('md')]: {
                                                minWidth: 120,
                                            },
                                        }}
                                        disabled={!isValid || !dirty || steps[activeStep].loading}
                                        loading={steps[activeStep].loading}
                                    >
                                        Finish
                                    </ButtonWithLoader>
                                ) : (
                                    <ButtonWithLoader
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            minWidth: 160,
                                            marginRight: theme.spacing(1),

                                            [theme.breakpoints.down('md')]: {
                                                minWidth: 120,
                                            },
                                        }}
                                        type={'button'}
                                        disabled={!isValid || steps[activeStep].loading}
                                        loading={steps[activeStep].loading}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            onNextOrSkip(values);
                                        }}
                                    >
                                        Continue
                                    </ButtonWithLoader>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default CustomStepper;
