import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import { Form } from '../../utils';

export interface AssignFormRequestParams {
    start_date_time: string;
    end_date_time: string | null;
    individual?: string;
    group?: string;
    timezone: string;
}

export const assignFormToCalendar = (
    id: string,
    params: AssignFormRequestParams,
): Promise<AxiosResponse<void>> => {
    return Request.post(`/data/forms/${id}/assign`, params);
};

export const duplicateForm = (
    id: string,
    params: { organization: string },
): Promise<AxiosResponse<Form>> => {
    return Request.post(`/data/forms/${id}/duplicate`, params);
};
